<div class="lib-prep-container">
  <div class="left">
    <mat-form-field *ngIf="!showCodeOnly" class="no-hint">
      <mat-label>{{ name }} Qty</mat-label>
      <mat-select [(ngModel)]="quantity">
        <mat-option *ngFor="let c of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>

    <lib-input-option
      label="{{ name }}"
      [selectedItemValue]="code"
      (selectionChange)="code = $event"
      transformType="preps"
      [optionsList]="codes"
    >
    </lib-input-option>

    <lib-input-option
      label="Location *"
      *ngIf="!showCodeOnly && !isShippedLoose"
      [selectedItemValue]="location"
      (selectionChange)="location = $event"
      [optionsList]="locations"
      transformType="value"
      [disabled]="!quantity"
    >
    </lib-input-option>

    <mat-form-field *ngIf="!showCodeOnly && !noReferencePoint" class="no-hint">
      <mat-label>Reference Point *</mat-label>
      <mat-select
        [value]="referencePoint"
        (selectionChange)="referencePoint = $event"
        [disabled]="location !== specialPrepLocation"
      >
        <mat-option *ngFor="let c of referencePoints" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <lib-frame-prep
    *ngIf="frameElevation"
    [frameElevation]="frameElevation"
    [subTypes]="subTypes"
    [showMessage]="!showCodeOnly"
    [prepLocationInputsFilled]="prepLocationInputsFilled"
  ></lib-frame-prep>
</div>
