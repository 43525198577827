import { Injectable } from '@angular/core'
import { User } from '@auth0/auth0-spa-js'
import { isEqual } from 'lodash'
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, filter } from 'rxjs'
import { Customer, Features } from '../../models'
import { CustomerService } from '../customer/customer.service'
import { FeatureFlagsSettings } from '../../models/feature-flags-settings.model'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class FlagsService {
  #flags: BehaviorSubject<Features> = new BehaviorSubject(null)
  flags$: Observable<Features> = this.#flags.asObservable()

  getFlags(): Features {
    return this.#flags.getValue()
  }

  customer: Customer
  user: User

  constructor(private customerSvc: CustomerService, private http: HttpClient) {
    combineLatest([
      this.customerSvc.customer$.pipe(
        filter((x) => !!x),
        distinctUntilChanged((x, y) => isEqual(x, y))
      ),
      this.customerSvc.authProfile$.pipe(
        filter((x) => !!x),
        distinctUntilChanged((x, y) => isEqual(x, y))
      ),
      this.featureFlagsSettings()
    ]).subscribe(([customer, user, settings]) => this.setFlags(customer, user, settings))
  }

  setFlags(customer: Customer, user: User, settings: FeatureFlagsSettings) {
    if (!customer || !user) return
    const flags = new Features(customer, user, settings)
    this.#flags.next(flags)
  }

  featureFlagsSettings(): Observable<FeatureFlagsSettings> {
    return this.http.get<FeatureFlagsSettings>(environment.onlineOrderingApiUrl + 'FeatureFlags')
  }
}
