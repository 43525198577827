<mat-form-field class="no-hint">
  <mat-select
    matInput
    [(ngModel)]="selectedValue"
    (selectionChange)="handleChange($event)"
    [disabled]="column.validators?.disabled ?? false"
  >
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{ option.text }}
    </mat-option>
  </mat-select>
</mat-form-field>
