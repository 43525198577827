<div class="header">
  <h2 class="header">{{ 'ERRORS.somethingWrong' | translate }}</h2>
  <button
    mat-icon-button
    type="button"
    attr.aria-label="{{ 'ACTIONS.closeModal' | translate }}"
    matTooltip="{{ 'ACTIONS.close' | translate }}"
    (click)="cancel()"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <img class="graphic" src="../../../../assets/img/illustrations/error.svg" alt="" />
  <h3 class="empty">{{ 'ERRORS.encounteredError' | translate }}</h3>
  <p>{{ data | translate }}</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="handleRetry()" color="primary" cdkFocusInitial>
    {{ 'ACTIONS.retry' | translate }}
  </button>
  <button mat-raised-button (click)="handleContactSupport()" color="primary">
    {{ 'ACTIONS.contactSupport' | translate }}
  </button>
</div>
