<div class="lib-prep-container">
  <form class="left" #PrepFieldsForm="ngForm">
    <mat-form-field>
      <mat-label>{{ title }}</mat-label>
      <input
        #lockPrepCodeInput
        matInput
        [matAutocomplete]="lockPrepCodeAutocomplete"
        name="prepCode"
        [ngModel]="getDisplayValue(code)"
        (ngModelChange)="setPrepCode($event)"
        [libValidValueValidator]="codes"
        validatorTransformFn="preps"
        required
      />
      <mat-autocomplete #lockPrepCodeAutocomplete="matAutocomplete">
        <mat-option
          *ngFor="let code of codes | libAutoCompleteFilter: lockPrepCodeInput.autocompleteValue:'preps'"
          [value]="getDisplayValue(code)"
        >
          {{ getDisplayValue(code) }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint *ngIf="linkToPrepCodes">
        <span class="lib-open-in-new-label">
          <a
            href="https://us.allegion.com/content/dam/allegion-us-2/portal/help/eto-training-resources/Door%20Nomenclature.pdf"
            target="__blank"
          >
            <span>{{ 'prepCodeGuide' | translate }}</span
            >&nbsp;<i class="material-icons">open_in_new</i>
          </a>
        </span>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="no-hint">
      <mat-label>{{ 'FORMFIELDS.location' | translate }}</mat-label>
      <input
        #locationInput
        matInput
        [matAutocomplete]="locationAutocomplete"
        name="location"
        [(ngModel)]="prep.locationType"
        [disabled]="!prep.code || noLock"
        required
        [libValidValueValidator]="locations"
        validatorTransformFn="value"
      />
      <mat-autocomplete #locationAutocomplete="matAutocomplete">
        <mat-option
          *ngFor="let option of locations | libAutoCompleteFilter: locationInput.autocompleteValue:'value'"
          [value]="option.value"
        >
          {{ option.value }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="no-hint">
      <mat-label>{{ 'Backset' }}</mat-label>
      <input matInput name="backset" [(ngModel)]="prep.backset" libApplyMeasure required [unitOfMeasure]="unitOfMeasure" [disabled]="isDPSeries" />
    </mat-form-field>

    <mat-form-field class="no-hint">
      <mat-label>{{ 'Reference Point' }}</mat-label>
      <mat-select
        required
        [(ngModel)]="prep.referencePoint"
        name="referencePoint"
        [disabled]="!code || noLock || code.fixedLocation || prep.locationType !== specialPrepLocation.value"
      >
        <mat-option *ngFor="let c of referencePoints" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>

    <lib-special-hardware [component]="this" [isSpecialPrep]="isSpecialPrep"></lib-special-hardware>
  </form>

  <lib-door-prep *ngIf="door" [door]="door" [prepLocationInputsFilled]="prepLocationInputsFilled"></lib-door-prep>
</div>
