<div class="lib-prep-container">
  <form class="left" #PrepFieldsForm="ngForm">
    <mat-form-field class="no-hint">
      <mat-label>{{ 'FORMFIELDS.surfaceBolt' | translate }}</mat-label>
      <input
        matInput
        [ngModel]="getDisplayValue(code)"
        (ngModelChange)="setPrepCode($event)"
        [matAutocomplete]="auto"
        #surfaceBoltPrepInput
        required
        [libValidValueValidator]="codes"
        validatorTransformFn="preps"
        name="surfaceBoltPrepCode"/>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of codes | libAutoCompleteFilter:surfaceBoltPrepInput.autocompleteValue:'preps'" [value]="getDisplayValue(option)">
          {{ getDisplayValue(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <lib-door-prep *ngIf="door" [door]="door"></lib-door-prep>
</div>
