<lib-dialog header="Actions" [loading]="loading$ | async">
  <div class="container">
    <div class="left">
      <div
        class="action"
        (click)="action = value"
        [ngClass]="{ active: value === action }"
        *ngFor="let value of actions"
      >
        {{ value | translate }}
      </div>
    </div>
    <div class="content">
      <ng-container [ngSwitch]="action">
        <div class="content" *ngSwitchCase="'save'">
          <ng-container *ngTemplateOutlet="saveOrDuplicate"></ng-container>
          <ng-container *ngTemplateOutlet="ActionButton; context: {clickAction: create, action}"></ng-container>
        </div>
        <div class="content" *ngSwitchCase="'update'">
          <ng-container *ngTemplateOutlet="saveOrDuplicate"></ng-container>
          <ng-container *ngTemplateOutlet="ActionButton; context: {clickAction: update, action}"></ng-container>
        </div>
        <div class="content" *ngSwitchCase="'duplicate'">
          <ng-container *ngTemplateOutlet="saveOrDuplicate"></ng-container>
          <ng-container *ngTemplateOutlet="ActionButton; context: {clickAction: create, action}"></ng-container>
        </div>
        <div class="content" *ngSwitchCase="'download'">
          <mat-form-field class="no-hint">
            <mat-label>File Name *</mat-label>
            <input matInput name="fileName" [ngModel]="name" (ngModelChange)="name = $event"/>
          </mat-form-field>
          <span class="section-header">{{ 'fileType' | translate }}</span>
          <span class="section-subheader">{{ 'INSTRUCTIONS.selectFileToDownload' | translate }}</span>
          <mat-radio-group [(ngModel)]="fileType">
            <mat-radio-button [value]="0">{{ 'pdf' | translate }}</mat-radio-button>
            <mat-radio-button [value]="1">{{ 'docx' | translate }}</mat-radio-button>
          </mat-radio-group>
          <ng-container *ngTemplateOutlet="ActionButton; context: {clickAction: download, action}"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</lib-dialog>

<ng-template #ActionButton let-action="action" let-clickAction="clickAction">
  <div class="spacer"></div>
  <div class="actions">
    <button class="mat-flat-button" color="primary" mat-flat-button [disabled]="!name || (!folder && action !== 'download') || (loading$ | async)" (click)="clickAction.bind(this)()">
      {{ action | translate }}
    </button>
  </div>
</ng-template>

<ng-template #saveOrDuplicate>
  <mat-form-field class="no-hint">
    <mat-label>Name *</mat-label>
    <input matInput [ngModel]="name" (ngModelChange)="name = $event"/>
  </mat-form-field>
  <span class="section-header">{{ 'destination' | translate }}</span>
  <span class="section-subheader">{{ 'INSTRUCTIONS.selectLocationToSaveFile' | translate }}</span>
  <div class="folders">
    <span class="folder-header">{{ 'elevationsCloudDocuments' | translate }}</span>
    <div class="overflow">
      <div
        (click)="folder = value"
        [ngClass]="{ active: value.id === folder?.id }"
        class="folder"
        *ngFor="let value of folders"
      >
        <mat-icon svgIcon="folder-vertical"></mat-icon>
        <span class="name">{{ value.name }}</span>
      </div>
    </div>
  </div>
</ng-template>
