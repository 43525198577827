<form [formGroup]="form" (submit)="onSubmit()">
  <div class="content">
    <h1 class="header">
      {{ getTitle(dialogData?.action, dialogData?.estimateType, type) | translate }}
      <button
        type="button"
        mat-icon-button
        attr.aria-label="{{ 'ACTIONS.closeModal' | translate }}"
        (click)="dialogRef.close()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </h1>
    <div mat-dialog-content>
      <div class="estimate-name">
        <mat-form-field>
          <mat-label>{{ 'LABEL.' + (type | lowercase) + 'Name' | translate }}</mat-label>
          <input
            #name
            id="estimate-name"
            type="text"
            matInput
            required
            maxlength="50"
            formControlName="name"
            (blur)="trim(name.value, 'name')"
            [placeholder]="'PLACEHOLDER.officeBuilding' | translate"
          />
        </mat-form-field>
      </div>

      <ng-container *ngIf="isStanley">
        <div class="estimate-name">
          <mat-form-field>
            <mat-label>{{ 'TABLE_HEADERS.jobAddress' | translate }}</mat-label>
            <textarea #jobAddress required type="text" matInput formControlName="description" maxlength="50"></textarea>
          </mat-form-field>
          <mat-form-field style="width: 50%">
            <mat-label>Customer Group</mat-label>
            <mat-select required formControlName="customerGroupId">
              <mat-option *ngFor="let custGroup of customerGroup" [value]="custGroup.id">{{
                custGroup.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="ballastic">
            <span class="mat-body-1">{{ 'stanleySpecialProduct' | translate }}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showShipFromLocation">
        <h3>{{ (flags?.enableSteelcraft ? 'TITLE.steelCraftRepublic' : 'TITLE.republic') | translate }}</h3>
        <form-group>
          <mat-checkbox *ngIf="dialogData.action === 'create'" formControlName="hollowMetal" color="primary">
            {{
              (flags?.enableSteelcraft
                ? 'LABEL.estimateContainsHollowMetal'
                : 'LABEL.estimateContainsHollowMetalRepublic'
              ) | translate
            }}
          </mat-checkbox>
          <mat-form-field style="width: 100%" class="padding s6 top">
            <mat-label>{{ 'LABEL.shipFromLocation' | translate }}</mat-label>
            <mat-select formControlName="shipFromLocationId">
              <mat-option *ngFor="let location of shipFromLocations" [value]="location.id">{{
                serviceCenterDescriptions[location.name] ?? location.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </form-group>
        <span *ngIf="showShipFromLocationWarning">{{ 'WARNINGS.FactoryHMProducts' | translate }}</span>
      </ng-container>

      <div *ngIf="isADSystems" class="freight">
        <h3>{{ 'TITLE.freightInformation' | translate }}</h3>
        <form-group>
          <mat-form-field>
            <mat-label>{{ 'TITLE.shipToState' | translate }}</mat-label>
            <mat-label *ngIf="flags.country === 'CAN'">{{ 'selectUSState' | translate }}</mat-label>
            <mat-select required formControlName="shipToState" [value]="dialogData?.estimate?.shipToStateCode">
              <mat-option *ngFor="let state of states" [value]="state.code">{{ state.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="freight-cost">
            <mat-label>{{ 'TITLE.freightCost' | translate }}</mat-label>
            <mat-icon matPrefix> attach_money </mat-icon>
            <input
              [ovtTrack]="'focus'"
              ovtAction="EditADSystemsEstimateFreight"
              [ovtProperties]="{ estimateId: dialogData?.estimate?.id }"
              matInput
              formControlName="freightCost"
              type="number"
            />
          </mat-form-field>
          <p *ngIf="flags?.adsEstimator" class="error-message" role="alert">
            {{ 'WARNINGS.overrideFreightCost' | translate }}
          </p>
        </form-group>
      </div>

      <ng-container *ngIf="flags.quotes && !isADSystems && !isStanley">
        <div class="search-div">
          <div class="table-header">
            <h3 id="availableQuotes">{{ 'availableQuotes' | translate }}</h3>
            <input matInput type="search" aria-labelledby="availableQuotes" formControlName="searchQuoteValue" />
          </div>
        </div>
        <oa-table [data]="quotes$ | async" [config]="tableConfig" [loading]="loading$ | async"> </oa-table>
      </ng-container>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button type="button" color="secondary" (click)="dialogRef.close()">
        {{ 'ACTIONS.cancel' | translate }}
      </button>
      <ng-container [ngSwitch]="dialogData?.action">
        <button
          tourAnchor="create-estimate"
          mat-raised-button
          id="submit"
          color="primary"
          type="submit"
          [disabled]="isDisabled()"
          *ngSwitchCase="'create'"
        >
          {{ 'ACTIONS.create' + type | translate }}
        </button>
        <button mat-raised-button color="primary" type="submit" [disabled]="isDisabled()" *ngSwitchCase="'edit'">
          {{ 'ACTIONS.update' + type | translate }}
        </button>
        <button mat-raised-button color="primary" type="submit" [disabled]="isDisabled()" *ngSwitchCase="'copy'">
          {{ 'ACTIONS.copy' + type | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</form>
