import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import { AuthService } from '../auth.service'
import { NewUserRequest } from '../../models'

@Injectable({
  providedIn: 'root'
})
export class EnvironmentInjectable {
  AlleAuthConfig: {
    clientID: string
    domain: string
    responseType: string
    audience: string
    redirectUri: string
    scope: string
    inAppRedirects: {
      loggedIn: string
      error: string
      logout: string
    }
    silentAuthPage: string
  }
  speccyApiUrl: string
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  api = environment.onlineOrderingApiUrl

  constructor(private auth: AuthService, private http: HttpClient) {}

  getUser() {
    return this.auth.getUser$()
  }

  public checkIsInternalUser(email: string): Observable<boolean> {
    const url = this.api + 'Users/emailExists?email=' + email
    return this.http.get<boolean>(url)
  }

  public requestUserAccess(user: NewUserRequest) {
    const url = this.api + 'Users/requestUserAccess'
    return this.http.post<boolean>(url, user)
  }
}
