<div class="side-bar" [ngClass]="{ 'collapsed': collapsed }" [ngStyle]="{width: collapsed ? '64px': '240px'}">
  <div class="logo-bar" [ngClass]="{ center: collapsed }">
    <div class="logo-container">
      <img class="logo" src="{{ environment.elevations.blobUrl }}common/E-icon.svg" />
    </div>

    <div [ngClass]="{collapsed}" class="app-title">{{'elevations' | translate}}</div>
  </div>


  <div class="buttons-wrapper" [ngClass]="{ 'bar-padding': collapsed }">
    <div class="top-buttons" [ngClass]="{collapsed}">
      <div *ngFor="let navButton of navButtons">
        <button
          class="left-bar-button padding s4 all"
          mat-flat-button
          [ngClass]="{ 'btn-active': navButton.active, collapsed }"
          (click)="select(navButton)"
          *ngIf="navButton.visible()"
        ><mat-icon [ngClass]="{ 'icon-padding': !collapsed }" svgIcon="{{navButton.icon}}"></mat-icon>
        {{collapsed ? '' : (navButton.name | translate)}}
        </button>
      </div>
    </div>

    <div class="bottom-buttons">
      <button class="left-bar-button footer-button" mat-flat-button (click)="openHelpDialog()">
        <mat-icon [ngClass]="{collapsed, 'icon-padding': !collapsed}">help</mat-icon>
        {{collapsed ? '' : ('ACTIONS.support' | translate)}}
      </button>
      <!-- collapse -->
      <button class="left-bar-button footer-button padding s4 all" mat-flat-button (click)="toggleCollaped()">
        <mat-icon [ngClass]="{collapsed, 'icon-padding' : !collapsed}"[svgIcon]="collapsed ? 'extend' : 'collapse'"></mat-icon>
        {{collapsed ? '' : ('BUTTON.collapse' | translate)}}
      </button>
    </div>
  </div>
</div>
