<div>
  <ng-container *ngIf="!column.icons; else multipleIcons">
    <ng-container *ngTemplateOutlet="iconTemplate; context: { icon: column }"> </ng-container>
  </ng-container>
</div>

<ng-template #multipleIcons>
  <ng-container *ngFor="let icon of column.icons">
    <ng-container *ngTemplateOutlet="iconTemplate; context: { icon }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #iconTemplate let-icon="icon">
  <span class="icon-with-label" *ngIf="showIcon(row, icon?.property)" [matTooltip]="icon?.tooltip">
    <mat-icon
      [color]="icon?.color"
      [svgIcon]="icon.materialIcon ? '' : icon.icon"
      data-testid="icon-element"
      [ngClass]="{ labeled: !!icon?.label }"
      >{{ icon.materialIcon ? icon.icon : '' }}</mat-icon
    >
    {{ icon?.label }}
  </span>
</ng-template>
