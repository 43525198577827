<div class="container">
  <div class="content">
    <div class="top">
      <div class="buttons">
        <div *ngFor="let t of types" [ngClass]="{ selected: t === type }" (click)="type = t">
          {{ t }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'Preps'" [ngSwitch]="prepType">
          <lib-door-preps *ngSwitchCase="'Door'"></lib-door-preps>
          <lib-frame-preps *ngSwitchCase="'Frame'"></lib-frame-preps>
        </ng-container>
        <ng-container *ngSwitchCase="'Locations'">
          <lib-generic-locations></lib-generic-locations>
        </ng-container>
        <ng-container *ngSwitchCase="'Hardware'">
          <lib-generic-hardware-type></lib-generic-hardware-type>
        </ng-container>
      </ng-container>
      <lib-filter [filterProduct]="prepType" (filterProductChange)="prepType = $event"></lib-filter>
    </div>
  </div>
</div>
<div libDialog></div>
