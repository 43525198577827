<div class="lib-prep-container">
  <form class="left" #PrepFieldsForm="ngForm">
    <mat-form-field class="no-hint">
      <mat-label>{{ 'FORMFIELDS.closer' | translate }}</mat-label>
      <input
        matInput
        [ngModel]="getDisplayValue(code)"
        (ngModelChange)="setPrepCode($event)"
        [matAutocomplete]="auto"
        #closerPrepInput
        required
        [libValidValueValidator]="codes"
        validatorTransformFn="preps"
        name="closerPrepCode"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of codes | libAutoCompleteFilter:closerPrepInput.autocompleteValue:'preps'"
          [value]="getDisplayValue(option)"
        >
          {{ getDisplayValue(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <lib-special-hardware [component]="this" [isSpecialPrep]="isSpecialPrep"></lib-special-hardware>
  </form>
  <lib-door-prep *ngIf="door" [door]="door"></lib-door-prep>
</div>
