<h3 class="padding s7 top left right">{{ 'prepInformation' | translate }}</h3>
<span class="content padding s7 left right">
  <div>
    <div class="row padding top s5">
      <div class="col grow padding right">
        <mat-form-field class="no-hint">
          <mat-label>{{'FORMFIELDS.code' | translate}}</mat-label>
          <input
            matInput
            [formControl]="form.controls.code"
          />
        </mat-form-field>
      </div>
      <div class="col grow padding left right">
        <mat-form-field class="no-hint">
          <mat-label>{{'FORMFIELDS.description' | translate}}</mat-label>
          <input
            matInput
            [formControl]="form.controls.description"
            [matTooltip]="description.value"
          />
        </mat-form-field>
      </div>
      <div class="col grow padding left right s3">
        <mat-form-field class="no-hint">
          <mat-label>{{'standardLocation' | translate}}</mat-label>
          <input
            matInput
            [formControl]="form.controls.standardLocation"
            libApplyMeasure
            [unitOfMeasure]="'Imperial'"
          />
        </mat-form-field>
      </div>
      <div class="col grow padding left">
        <mat-form-field class="no-hint">
          <mat-label>{{'fixedLocation' | translate}}</mat-label>
          <mat-select [formControl]="form.controls.fixedLocation">
            <mat-option [value]="false">{{ 'no' | translate }}</mat-option>
            <mat-option [value]="true">{{ 'yes' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col grow">
        <mat-form-field class="no-hint">
          <mat-label>{{'pricingCategory' | translate}}</mat-label>
          <mat-select [formControl]="form.controls.pricingCategory">
            <mat-option [value]="null">--</mat-option>
            <mat-option *ngFor="let c of pricingCategories" [value]="c">{{ c }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col grow padding right">
        <mat-form-field class="no-hint">
          <mat-label>{{'shape' | translate}}</mat-label>
          <mat-select [formControl]="form.controls.shape">
            <mat-option [value]="null">--</mat-option>
            <mat-option *ngFor="let c of shapes" [value]="c">{{ c }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col grow padding right left">
        <mat-form-field class="no-hint">
          <mat-label>{{'height' | translate}}</mat-label>
          <input
            matInput
            [formControl]="form.controls.height"
            libApplyMeasure
            [unitOfMeasure]="'Imperial'"
          />
        </mat-form-field>
      </div>
      <div class="col grow padding right left">
        <mat-form-field class="no-hint">
          <mat-label>{{'width' | translate}}</mat-label>
          <input
            matInput
            [formControl]="form.controls.width"
            libApplyMeasure
            [unitOfMeasure]="'Imperial'"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div>
    <div class ="row">
      <h3>{{ 'categories' | translate }}</h3>
    </div>
  </div>
  <div class="categories row">
    <mat-checkbox color="primary" [(ngModel)]="c.checked" *ngFor="let c of categories; let i = index" [ngStyle]="{order: i}">{{
      c.name
    }}</mat-checkbox>
  </div>
  <h3 class="padding top s7">{{ 'brand' | translate }}</h3>
  <div class="brands row">
      <mat-checkbox color="primary" [checked]="isSteelcraft.value" (change)="isSteelcraft.patchValue($event.checked);">{{
        'BRAND.steelCraft' | translate
      }}</mat-checkbox>
      <mat-checkbox color="primary" [checked]="isRepublic.value" (change)="isRepublic.patchValue($event.checked);">{{
        'BRAND.republic' | translate
      }}</mat-checkbox>
  </div>
</span>
<span class="footer padding s7 left right">
  <div class="spacer"></div>
  <div class="actions">
    <button class="mat-flat-button margin s3 right" mat-flat-button color="warn" (click)="delete()" *ngIf="value.id !== null">
      {{ 'BUTTON.delete' | translate }}</button>
      <button class="mat-flat-button" color="primary" mat-flat-button [disabled]="form.invalid" (click)="save()">
        {{ 'BUTTON.save' | translate }}</button>
      </div>
</span>
