import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule, Type } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { StepperComponent } from './components/stepper/stepper.component'
import { InputSelectComponent } from './components/input-select/input-select.component'
import { AutoCompleteDirective } from './directives/autocomplete.directive'
import { DynamicRowDirective } from './directives/dynamic-row.directive'
import { IStorageService, STORAGE_SERVICE } from './interfaces/i-storage-service'
import { MaterialModule } from './material/material.module'
import { AutoCompleteFilterPipe } from './pipes/autocompleteFilter.pipe'
import { SEARCH_AND_FILTER_SERVICE, ISearchAndFilterService } from './types/search-and-filter-base'
import { ValidValueValidatorDirective } from './directives/validValue.validator'
import { TableModule } from './components/table/table.module'
import { UploadComponent } from './components/upload/upload.component'
import { COMMON_ENVIRONMENT_TOKEN, ICommonEnvironment } from './injection-tokens/common-environment'
import { AUTH_SERVICE, BaseAuthService } from './injection-tokens'
import { CUSTOMER_SERVICE, BaseCustomerService } from './injection-tokens/customer-service'
import { SupportDialogComponent } from './components/support-dialog/support-dialog.component'
import { BaseFlagsService, FLAGS_SERVICE } from './injection-tokens/flags-service'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AutoCompleteDirective,
    AutoCompleteFilterPipe,
    ValidValueValidatorDirective,
    DynamicRowDirective,
    TableModule,
    InputSelectComponent,
    StepperComponent,
    UploadComponent,
    SupportDialogComponent
  ],
  exports: [
    InputSelectComponent,
    StepperComponent,
    SupportDialogComponent,
    UploadComponent,
    MaterialModule,
    AutoCompleteDirective,
    AutoCompleteFilterPipe,
    ValidValueValidatorDirective,
    DynamicRowDirective,
    TableModule
  ]
})
export class OEOCommonModule {
  static forRoot(
    storageService: Type<IStorageService>,
    searchAndFilterService: Type<ISearchAndFilterService>,
    environment: ICommonEnvironment,
    authService: Type<BaseAuthService>,
    customerService: Type<BaseCustomerService>,
    flagsService: Type<BaseFlagsService>
  ): ModuleWithProviders<OEOCommonModule> {
    return {
      ngModule: OEOCommonModule,
      providers: [
        { provide: STORAGE_SERVICE, useClass: storageService },
        {
          provide: SEARCH_AND_FILTER_SERVICE,
          useClass: searchAndFilterService
        },
        {
          provide: COMMON_ENVIRONMENT_TOKEN,
          useValue: environment
        },
        {
          provide: AUTH_SERVICE,
          useClass: authService
        },
        {
          provide: CUSTOMER_SERVICE,
          useClass: customerService
        },
        {
          provide: FLAGS_SERVICE,
          useClass: flagsService
        }
      ]
    }
  }
}
