/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable, TemplateRef } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'
import { DoorMarking, EstimateProductDoorMarking, Quote, Tooltip } from '../../models'
import { Estimate } from '../../models/estimate.model'
import { ErrorModalComponent } from '../../shared/components/error-modal/error-modal.component'
import { StorageService } from '../local-storage/local-storage.service'
import { EstimateHardwareSet } from './../../models/estimateHardwareSet.model'
import { AlertService } from '@oeo/common'
import { CustomerAccount } from '@oeo/common'

export enum SupportedLanguages {
  en = 'english',
  fr = 'french'
}
export const ZipRegex = /^\d{5}(-\d{4})?$/
export const CanadaZipRegex = /([A-Z0-9]){3}(\s|-){0,3}([A-Z0-9]){3}/gs
export const PhoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/
export const PhoneRegexNumberOnly = /^\d{10}$/
export const EmailRegex = /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})$/
export const NonAllegionEmailRegex = /^\w+([\.\-\+]?\w+)*@(?!(?:allegion)\.)\w+([\.-]?\w+)*(\.\w{2,20})$/i
export const NameRegex = /.*?[^\s]{1,}.*?/
export const PrnRegex = /(RN\d{6})/i
export const FileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
export const Errors = {
  required: 'ERRORS.requiredField',
  invalidDate: 'ERRORS.invalidDate'
}

export enum BannerNotificationTypes {
  internal = 'InternalNotification',
  ie = 'IENotification',
  pe = 'PENotification'
}

export type BannerNotifications = {
  [key in BannerNotificationTypes]: boolean
}
export const emptyNotifications: BannerNotifications = {
  InternalNotification: false,
  IENotification: false,
  PENotification: false
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private _bannerNotifications = new BehaviorSubject<BannerNotifications>(emptyNotifications)
  bannerNotifications$ = this._bannerNotifications.asObservable()
  downloadingFileType = 'PDF'

  get bannerNotifications() {
    return this._bannerNotifications.getValue()
  }

  #breadCrumbProjectedContent: Record<string, TemplateRef<any>> = {}

  constructor(
    public dialog: MatDialog,
    public translateService: TranslateService,
    private storageService: StorageService,
    public alertService: AlertService
  ) {}

  showDownloadingSnackBar(fileType: string = 'PDF') {
    switch (fileType) {
      case 'pdf':
        this.downloadingFileType = 'PDF'
        break
      case 'zip':
        this.downloadingFileType = 'Zip'
        break
      case 'xlsx':
        this.downloadingFileType = 'Excel'
        break
      default:
        break
    }
    this.openAlert(
      {
        // here specify the position
        title: 'ACTIONS.downloading' + this.downloadingFileType,
        state: 'info'
      },
      4000,
      'topRight'
    )
  }

  setBreadCrumbContent(key: string, content: TemplateRef<any>) {
    this.#breadCrumbProjectedContent[key] = content
  }

  getBreadCrumbContent(key: string) {
    return this.#breadCrumbProjectedContent[key]
  }

  removeBreadCrumbContent(key: string) {
    delete this.#breadCrumbProjectedContent[key]
  }

  closeSnackBar() {
    this.alertService.closeAlert()
  }

  /**
   * Used to translate a string to the appropriate language.
   *
   * @param text - the text to translate
   * @returns {string} - the translated text
   */
  translate(text: string): string {
    return this.translateService.instant(text)
  }

  handleError(message: string) {
    this.dialog.open(ErrorModalComponent, {
      data: message,
      disableClose: true
    })
  }

  handleErrorObject(error: HttpErrorResponse) {
    if (error.error instanceof Blob) {
      this.handleHttpErrorResponse(error)
    } else {
      const message = error.error || error.message || 'ERRORS.encounteredError'
      this.dialog.open(ErrorModalComponent, {
        data: message,
        disableClose: true
      })
    }
  }

  private handleHttpErrorResponse(errorResponse: HttpErrorResponse) {
    const reader = new FileReader()
    reader.onloadend = () => {
      const errorText = reader.result as string
      this.dialog.open(ErrorModalComponent, {
        data: errorText,
        disableClose: true
      })
    }
    reader.readAsText(errorResponse.error)
  }

  formatPhoneNumber(val: string) {
    if (/^\d{1,}$/.test(val)) {
      if (val.length === 10) {
        return val.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3')
      } else if (val.length > 20) {
        return val.substring(0, 20)
      }
    }
    return val
  }

  isKeyed(estimate: Estimate): boolean {
    const keyableHardwareSets = estimate.hardwareSets
      ? estimate?.hardwareSets.filter((hws) => this.hwSetIsKeyed(hws)).length
      : 0
    const keyableProducts = estimate.products
      ? estimate?.products.filter((p) => p?.product?.isKeyed && !p?.product?.isFastTrack).length
      : 0
    return keyableHardwareSets + keyableProducts > 0
  }

  hwSetIsKeyed(hwSet: EstimateHardwareSet): boolean {
    return (
      hwSet?.hardwareSet.keyable &&
      !hwSet?.hardwareSet?.products.some((product) => {
        return product?.isFastTrack
      })
    )
  }

  setLanguageHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
    const language = this.translateService.currentLang
    return headers.set('Accept-Language', language)
  }

  /**
   * Takes Date and adds quantity version of days
   */
  addDaysToDate(date: Date, quantity: number): Date {
    return new Date(date.setDate(date.getDate() + quantity))
  }

  formatDate(date: Date): string {
    const month = date.getMonth() + 1
    const MM = month.toString().length < 2 ? '0' + month : month

    const day = date.getDate()
    const DD = day.toString().length < 2 ? '0' + day : day

    const year = date.getFullYear()

    return `${MM}/${DD}/${year}`
  }

  calcContentHeight(calculate: boolean = true) {
    setTimeout(() => this.calculateContentHeight(calculate), 100)
  }

  private calculateContentHeight(calculate: boolean) {
    if (!document) {
      return
    }
    const documentHeight = window.innerHeight
    const router = document.getElementById('router')

    const _notifications = document.getElementsByClassName('notification-container')

    if (!router) return
    if (!calculate) {
      router.setAttribute('style', `padding-bottom: 0px; min-height: calc(100vh-55px)`)
      return
    }
    if (_notifications.length === 0) {
      return
    }
    const heights = []
    for (let i = 0; i < _notifications.length; i++) {
      const element = _notifications.item(i) as HTMLElement
      if (!element.classList.contains('closed')) heights.push(element.offsetHeight)
    }
    const totalOffsetHeight = heights.reduce((a, b) => a + b, 0)
    const notificationHeight = totalOffsetHeight

    router.setAttribute(
      'style',
      `padding-bottom:` + notificationHeight + 'px; min-height: ' + (documentHeight - 55 - notificationHeight) + 'px'
    )
  }

  initializeBanners(internal: boolean, handleIe: boolean) {
    const bannerMessages: BannerNotifications = emptyNotifications
    if (internal) {
      const showInternalNotification = this.storageService.containsItem('InternalNotification')
        ? this.storageService.getItem('InternalNotification')
        : true
      bannerMessages.InternalNotification = showInternalNotification
    }
    if (handleIe) {
      const isIe: boolean = navigator.appVersion.includes('Triden')
      bannerMessages.IENotification = isIe
    }
    this._bannerNotifications.next(bannerMessages)
    this.calcContentHeight()
    return bannerMessages
  }

  /**
   * Shows or hides notification - if show is true, then check local storage if the user had
   * hidden the notification before and combine the previous state with show
   *
   * @param message
   * @param show
   */
  showHideNotification(message: BannerNotificationTypes, hide: boolean, show: boolean) {
    const state: boolean = this.storageService.containsItem(message) ? this.storageService.getItem(message) : true
    if (hide) {
      this._bannerNotifications.next({ ...this.bannerNotifications, [message]: show })
      this.storageService.setItem(message, false)
    } else {
      if (show) {
        this._bannerNotifications.next({ ...this.bannerNotifications, [message]: state })
      } else {
        this._bannerNotifications.next({ ...this.bannerNotifications, [message]: show })
      }
    }
    this.calcContentHeight()
  }

  showQuoteNotificationChange(quote: Quote) {
    this.openAlert(
      {
        title: this.translate('ALERT.quoteUpdated'),
        description: `${this.translate('quote')}${quote?.quoteNumber ? ' #' + quote?.quoteNumber : ''} - ${
          quote?.name ?? 'Buying Program'
        } ${this.translate('hasBeenApplied')}`,
        state: 'success'
      },
      3500,
      'topCenter'
    )
  }

  openAlert(...args: Parameters<AlertService['openAlert']>): void {
    this.alertService.openAlert(...args)
  }

  formatDoorMarking(marks: (DoorMarking | EstimateProductDoorMarking)[]): Tooltip {
    if (!marks || marks.length < 1) {
      return {}
    }
    const actualValue: string = marks.map((mark) => mark.name).join(', ')
    const displayValue = actualValue.length > 20 ? actualValue.substring(0, 20) + '...' : actualValue

    return {
      actualValue,
      displayValue
    }
  }

  displayName(account: CustomerAccount): string {
    return account?.customerName || ''
  }
}
