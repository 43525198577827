<tour-step-template>
  <ng-template let-step="step">
    <mat-card class="tour-card" (click)="$event.stopPropagation()">
      <mat-card-header class="header">
        <mat-card-title>
          <h3 class="mat-subtitle-1">
            {{ step.title }}
          </h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content [innerHTML]="step.content"></mat-card-content>
      <mat-card-actions [class.no-progress]="!step.showProgress">
        <mat-icon svgIcon="oeo-arrow" class="oeo-icon"></mat-icon>
        <div *ngIf="step.showProgress">
          {{ tourService.steps?.indexOf(step) + 1 }} / {{ tourService.steps?.length }}
        </div>
        <button
          *ngIf="tourService.hasNext(step) && !step.nextOnAnchorClick"
          class="next"
          (click)="tourService.next()"
          mat-flat-button
          color="primary"
        >
          {{ step.nextBtnTitle }}
          <mat-icon iconPositionEnd>chevron_right</mat-icon>
        </button>
        <button *ngIf="!tourService.hasNext(step)" mat-flat-button color="primary" (click)="tourService.end()">
          {{ step.endBtnTitle }}
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-template>
</tour-step-template>
