import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { User } from '@auth0/auth0-spa-js'
import { isEqual } from 'lodash'
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  combineLatest,
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  merge,
  mergeMap,
  of,
  share,
  skipWhile,
  switchMap,
  take,
  tap
} from 'rxjs'
import { environment } from '../../../environments/environment'
import { Customer } from '../../models'
import { AuthService } from '../auth.service'
import { HelperService } from '../helper/helper.service'
import { StorageService } from '../local-storage/local-storage.service'
import { formatBranchName, CustomerAccount, BaseCustomerService } from '@oeo/common'
@Injectable({
  providedIn: 'root'
})
export class CustomerService implements BaseCustomerService {
  #customer: BehaviorSubject<Customer> = new BehaviorSubject(null)
  customer$: Observable<Customer> = this.#customer.asObservable().pipe(filter((customer) => !!customer))
  authProfile$: Observable<User> = this._auth.userProfile$.pipe(filter((user) => !!user))

  get customer(): Customer {
    return this.#customer.getValue()
  }
  url: string = environment.onlineOrderingApiUrl + 'Customer'

  #parentCompanies = new BehaviorSubject<CustomerAccount[]>([])
  parentCompanies$ = this.#parentCompanies.asObservable()
  get parentCompanies(): CustomerAccount[] {
    return this.#parentCompanies.value
  }
  /* Initialize the parentCompanyId with the value from local storage */
  #parentCompanyId = new BehaviorSubject<string>(this.storageService.getItem('parentCompanyId'))
  parentCompanyId$ = this.#parentCompanyId.asObservable().pipe(
    filter((value) => !!value),
    distinctUntilChanged((x, y) => x?.toLowerCase() === y?.toLowerCase())
  )
  get parentCompanyId(): string {
    return this.#parentCompanyId.getValue()?.toUpperCase()
  }
  set parentCompanyId(value: string) {
    this.storageService.setItem('parentCompanyId', value)
    this.#parentCompanyId.next(value)
  }

  #parentCompany = new BehaviorSubject<CustomerAccount>(null)
  parentCompany$ = this.#parentCompany.asObservable()
  set parentCompany(customerAccount: CustomerAccount) {
    if (!customerAccount || this.parentCompany?.accountId === customerAccount?.accountId) return
    if (this.parentCompany && this.parentCompany?.accountId !== customerAccount?.accountId) this.clearCompany()
    this.parentCompanyId = customerAccount.accountId
    this.#parentCompany.next(customerAccount)
  }

  get parentCompany(): CustomerAccount {
    return this.#parentCompany.value
  }

  shouldRouteToRoot(newParentCompanyAccId: string) {
    return !!this.parentCompany && this.parentCompany?.accountId?.toLowerCase() !== newParentCompanyAccId?.toLowerCase()
  }

  #targetBranchCustomerId = new BehaviorSubject<string>(this.storageService.getItem('targetBranchCustomerId'))
  targetBranchCustomerId$ = this.#targetBranchCustomerId.asObservable().pipe(filter((value) => !!value))
  get targetBranchCustomerId(): string {
    return this.#targetBranchCustomerId.getValue()?.toUpperCase()
  }
  set targetBranchCustomerId(value: string) {
    if (value?.toLowerCase() === this.targetBranchCustomerId?.toLowerCase()) return
    this.storageService.setItem('targetBranchCustomerId', value)
    this.#targetBranchCustomerId.next(value)
  }

  #customerBranches = new BehaviorSubject<CustomerAccount[]>([])
  customerBranches$ = this.#customerBranches.asObservable()
  get customerBranches(): CustomerAccount[] {
    return this.#customerBranches.getValue()
  }

  get selectedBranch() {
    return this.customerBranches?.find(
      (branch: CustomerAccount) => branch.accountId.toLowerCase() === this.targetBranchCustomerId?.toLowerCase()
    )
  }

  get defaultBranchOnLogin(): string {
    return this.storageService.getItem('defaultBranchOnLogin')
  }

  set defaultBranchOnLogin(value: string) {
    if (value === this.defaultBranchOnLogin) {
      this.storageService.removeItem('defaultBranchOnLogin')
    } else {
      this.storageService.setItem('defaultBranchOnLogin', value)
    }
  }

  searchCompany$ = new Subject<string>().pipe(
    debounceTime(500),
    combineLatestWith(this.isInternalUser$.pipe(take(1))),
    skipWhile(
      ([inputValue, isInternal]) =>
        !(isInternal && !!inputValue && typeof inputValue === 'string' && inputValue.length > 0)
    ),
    map(([inputValue]) => inputValue),
    distinctUntilChanged(),
    share(),
    switchMap((searchText) => this.searchCompanies(searchText))
  )

  searchingBranches$ = new BehaviorSubject<{ loading: boolean; error: boolean }>({ loading: false, error: false })
  searchingCompanies$ = new BehaviorSubject<{ loading: boolean; error: boolean }>({ loading: false, error: false })
  externalUserStateLoading$ = new BehaviorSubject<boolean>(true)
  unauthorized$ = new BehaviorSubject<boolean>(false)

  constructor(
    private http: HttpClient,
    private _auth: AuthService,
    private helperService: HelperService,
    private storageService: StorageService,
    private router: Router
  ) {
    this.searchCompany$.subscribe({})

    forkJoin([this.isInternalUser$.pipe(take(1)), this.isExternalUser$.pipe(take(1))])
      .pipe(
        tap(([isInternal, isExternal]) => {
          if (isExternal) {
            this.searchingCompanies$.next({ loading: true, error: false })
            this.externalUserStateLoading$.next(true)
          }
          if (isInternal) {
            this.externalUserStateLoading$.next(false)
          }
        }),
        filter(([isInternal, isExternal]) => isInternal || isExternal),
        switchMap(([, isExternal]) => {
          return merge(
            /* For external users, we immediately load the customer object */
            ...(isExternal ? [this.#getCustomer()] : []),
            this.targetBranchCustomerId$.pipe(switchMap(() => this.#getCustomer()))
          ).pipe(combineLatestWith(of(isExternal)))
        }),
        /* Skip if the customer objects passed in are equal */
        distinctUntilChanged(([previousCustomer], [nextCustomer]) => isEqual(previousCustomer, nextCustomer))
      )
      .subscribe({
        next: ([customer, isExternal]) => {
          this.#customer.next(customer)
          this.storageService.setItem('customer', customer)

          /* For external users, the parent company id is set from the customer.customerId */
          if (isExternal) {
            this.parentCompanyId = customer.customerId?.toUpperCase()
          }
        },
        error: (error) => this.helperService.handleError(error.message)
      })

    /* Check if the customer is loaded (for internal users) when a user selects a company */
    this.parentCompanyId$
      .pipe(
        tap(() => this.searchingBranches$.next({ loading: true, error: false })),
        combineLatestWith(this.isExternalUser$.pipe(take(1)), this.isInternalUser$.pipe(take(1))),
        mergeMap(([parentCompanyId, isExternal, isInternal]) => {
          const branches$ = combineLatest([
            this.getCustomerBranches(parentCompanyId).pipe(
              catchError(() => {
                this.searchingBranches$.next({ loading: false, error: true })
                if (environment.featureFlags.enableMultiBranch) {
                  this.helperService.openAlert({ title: 'ERRORS.gettingBranches', state: 'error' })
                }
                return EMPTY
              })
            ),
            of(isExternal),
            of(isInternal)
          ])
          if (isInternal) {
            return this.#getCustomer().pipe(
              mergeMap((customer) => {
                this.#customer.next(customer)
                this.storageService.setItem('customer', customer)
                return branches$
              })
            )
          } else if (isExternal) return branches$
          else return EMPTY
        })
      )
      .subscribe(([customerBranches, isExternal, isInternal]) => {
        if (isExternal && customerBranches.length === 0) {
          this.unauthorized$.next(true)
        }
        this.#customerBranches.next(customerBranches)
        /* We then assign the parent company based on the holdingAccountId */
        this.parentCompany = customerBranches.find((br: CustomerAccount) => br.accountId === br.holdingAccountId)

        /** Before setting the target branch to the defaultBranchOnLogin, check whether that branch is on the list */
        const defaultLoginBranchIsInBranchList = !!customerBranches.find(
          (branch: CustomerAccount) => branch.accountId === this.defaultBranchOnLogin
        )
        /* Feature flagged case */
        if (!environment.featureFlags.enableMultiBranch) {
          if (isInternal) {
            this.parentCompanyId = this.customer?.customerId
          }
          this.targetBranchCustomerId = this.customer?.customerId
        }

        if (isExternal && !this.targetBranchCustomerId && customerBranches.length > 0) {
          if (customerBranches.length === 1) {
            this.targetBranchCustomerId = customerBranches.first().accountId
          } else {
            this.externalUserStateLoading$.next(false)
          }
          if (this.defaultBranchOnLogin && defaultLoginBranchIsInBranchList) {
            this.targetBranchCustomerId = this.defaultBranchOnLogin
          }
        }

        if (isExternal) {
          this.searchingCompanies$.next({ loading: false, error: false })
        }
        this.searchingBranches$.next({ loading: false, error: false })
      })

    this.customer$.pipe().subscribe((customer) => {
      if (customer) {
        this.storageService.setItem('customer', customer)
        this.storageService.setItem('parentCompanyId', customer.customerId)
      }
    })

    this.isInternalOrHasUserRole$.subscribe((isUserAuthorized) => {
      if (!isUserAuthorized) {
        this.unauthorized$.next(true)
      }
    })
  }

  #getCustomer(): Observable<Customer> {
    return this.http
      .get<Customer>(this.url + `?internalUser=${this.storageService.getItem('internal') || false}`)
      .pipe(filter((customer) => !!customer))
  }

  public getCustomerBranches(customerId: string): Observable<CustomerAccount[]> {
    const url = `${this.url}/branches?accountId=${customerId}`
    return this.http.get<CustomerAccount[]>(url).pipe(map((branches) => branches ?? []))
  }

  public searchCompanies(searchValue: string): Observable<CustomerAccount[]> {
    this.searchingCompanies$.next({ loading: true, error: false })
    const url = `${this.url}/search?searchText=${encodeURIComponent(searchValue)}`
    return this.http.get<CustomerAccount[]>(url).pipe(
      tap((companies) => {
        this.#parentCompanies.next(companies.map((company) => new CustomerAccount(company)))
        this.searchingCompanies$.next({ loading: false, error: false })
      }),
      catchError(() => {
        this.searchingCompanies$.next({ loading: false, error: true })
        this.helperService.openAlert({ title: 'ERRORS.gettingCompanies', state: 'error' })
        return EMPTY
      })
    )
  }

  selectBranch(selectedBranchAccountId: string): void {
    if (!selectedBranchAccountId) return
    if (this.storageService.getItem('targetBranchCustomerId') !== selectedBranchAccountId) {
      this.targetBranchCustomerId = selectedBranchAccountId
      this.storageService.removeItem('customer')
      this.router.navigateByUrl('landing')
    }
  }

  clearCompany(): void {
    this.#parentCompanies.next([])
    this.#customerBranches.next([])
    this.targetBranchCustomerId = null
  }

  checkForUserRole(roles: string[]): boolean {
    return roles?.includes('User')
  }

  getUserType(value: User): 'internal' | 'external' | null {
    if (!value) return null
    let type: 'internal' | 'external' | null
    type = value['http://allegion.com/claims/user_type' as keyof User]

    if (type === 'external') {
      type = this.checkForUserRole(value['http://allegion.com/claims/roles' as keyof User]) ? 'external' : null
    }
    this.storageService.setItem('internal', type === 'internal')
    return type
  }

  get userType$(): Observable<'internal' | 'external' | null> {
    return this.authProfile$.pipe(map((value) => this.getUserType(value)))
  }

  get isExternalUser$(): Observable<boolean> {
    return this.authProfile$.pipe(map((value) => this.getUserType(value) === 'external'))
  }

  get isInternalUser$(): Observable<boolean> {
    return this.authProfile$.pipe(map((value) => this.getUserType(value) === 'internal'))
  }

  getBranchDisplayName = formatBranchName

  get isInternalOrHasUserRole$(): Observable<boolean> {
    return this.authProfile$.pipe(map((value) => !!this.getUserType(value)))
  }
}
