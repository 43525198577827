<form #SpecialQuestionsForm="ngForm">
  <ng-container *ngIf="isSpecialPrep">
    <mat-form-field>
      <mat-label>{{ 'type' | translate }}</mat-label>
      <input
        matInput
        name="hardwareType"
        required
        [(ngModel)]="component.hardwareType"
        #hardwareTypeInput
        [matAutocomplete]="hardwareTypeAutoComplete"
        [libValidValueValidator]="component.hardwareTypes"
      />
      <mat-autocomplete #hardwareTypeAutoComplete="matAutocomplete">
        <mat-option
          *ngFor="let option of component.hardwareTypes | libAutoCompleteFilter: hardwareTypeInput.autocompleteValue"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint *ngIf="message">
        <mat-icon svgIcon="info"></mat-icon>
        {{ message }}
      </mat-hint>
    </mat-form-field>

    <mat-checkbox color="primary" style="margin-bottom: 20px" name="prepNotListed" [(ngModel)]="prepNotListed">{{
      'prepNotListed' | translate
    }}</mat-checkbox>

    <mat-form-field class="no-hint" *ngIf="!prepNotListed">
      <mat-label>{{ 'manufacturer' | translate }}</mat-label>
      <input
        matInput
        name="manufacturer"
        required
        [(ngModel)]="component.manufacturer"
        #manufacturerInput
        [matAutocomplete]="manufacturerAutoComplete"
        [libValidValueValidator]="component.manufacturers"
      />
      <mat-autocomplete #manufacturerAutoComplete="matAutocomplete">
        <mat-option
          *ngFor="let option of component.manufacturers | libAutoCompleteFilter: manufacturerInput.autocompleteValue"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="no-hint" *ngIf="prepNotListed">
      <mat-label>{{ 'manufacturer' | translate }}</mat-label>
      <input matInput name="manufacturer" required [(ngModel)]="component.manufacturer" />
    </mat-form-field>

    <mat-form-field class="no-hint" *ngIf="prepNotListed">
      <mat-label>{{ 'FORMFIELDS.id' | translate }}</mat-label>
      <input matInput required name="productId" [(ngModel)]="component.productId" />
    </mat-form-field>

    <mat-form-field class="no-hint" *ngIf="!prepNotListed">
      <mat-label>{{ 'FORMFIELDS.id' | translate }}</mat-label>
      <input
        matInput
        required
        name="productId"
        [(ngModel)]="component.productId"
        #idInput
        [matAutocomplete]="idAutoComplete"
        [libValidValueValidator]="component.productIds"
      />
      <mat-autocomplete #idAutoComplete="matAutocomplete">
        <mat-option
          *ngFor="let option of component.productIds | libAutoCompleteFilter: idInput.autocompleteValue"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <ng-container *ngFor="let question of component.questions; let i = index">
      <mat-form-field class="no-hint" *ngIf="component.isQuestionVisible(question)">
        <mat-label>{{ question.title }}</mat-label>
        <input
          required
          [ngModel]="question.value"
          [name]="'question-' + i"
          (ngModelChange)="component.setQuestionValue(question, $event, true)"
          #questionInput
          matInput
          [libValidValueValidator]="prepNotListed ? []: question.values"
        />
        <mat-autocomplete>
          <mat-option
            *ngFor="let option of question.values | libAutoCompleteFilter: questionInput.autocompleteValue"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </ng-container>
</form>
