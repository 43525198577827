import { CommonModule } from '@angular/common'
import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { TMimeTypes, MimeTypes } from '../../enums'
import { isExpectedMimeType } from '../../helpers'
import { MaterialModule } from '../../material/material.module'
import { AlertService } from '../../services/alert.service'
import { FormsModule } from '@angular/forms'
import * as _ from 'lodash'

@Component({
  selector: 'oa-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  standalone: true,
  imports: [MaterialModule, CommonModule, TranslateModule, DragDropModule, FormsModule]
})
export class UploadComponent implements OnInit, OnChanges {
  @Input() acceptedFileTypes: TMimeTypes[] = ['doc', 'docx', 'xls', 'xlsx', 'pdf']
  @Input() maxFileSize: number = 5 // in megabytes - defaults to 5MB
  @Input() label = 'upload'
  @Input() multiple = false
  @Input() clear = true
  @Output() file: EventEmitter<File | File[] | null> = new EventEmitter()
  _files: File[] = []
  files!: File[] | null
  formattedFileTypes!: string

  hover = false
  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.formattedFileTypes = this.acceptedFileTypes.reduce((allTypes, type, index, array) => {
      const addComma = index + 1 < array.length
      const addAnd = index + 2 === array.length
      const end = index + 1 === array.length
      return (
        allTypes +
        '.' +
        type +
        `${addComma ? ', ' : ''}` +
        `${addAnd ? ' and ' : ''}` +
        `${end && array.length > 1 ? '.' : ''}`
      )
    }, '')
  }

  ngOnChanges(event: SimpleChanges) {
    if (event['clear'] && this.clear) {
      this._files = []
      this.files = []
      this.file.emit(null)
    }
  }

  onUpload(event: Event): File | File[] | undefined {
    const inputElement = event.target as HTMLInputElement

    if (!event?.target || !inputElement.files?.length) {
      return
    }
    if (this.multiple) {
      const files = Array.from(inputElement.files ?? [])
      this.handleMultipleFiles(files)
      this.files = null
      return files
    } else {
      const file = inputElement.files[0]
      this.handleSingleFile(file)
      this.files = null
      return file
    }
  }

  handleSingleFile(file: File): void {
    if (!file) {
      this.file.emit(null)
      return
    }
    if (!this.fileSizeValid(file)) {
      return
    }
    this.fileTypeIsValid(file).then((isValidType) => {
      if (!isValidType) {
        return
      }
      this._files = [file]
      this.file.emit(file)
    })
  }

  handleMultipleFiles(files: File[]): void {
    if (!files?.length) {
      this.file.emit(null)
      return
    }
    if (files.map((file) => this.fileSizeValid(file)).includes(false)) return
    Promise.all(files.map((file) => this.fileTypeIsValid(file))).then((allValid) => {
      if (allValid.some((valid) => !valid)) return
      this._files = [...this._files, ...files]
      this.file.emit(this._files)
    })
  }

  isAcceptebleFileType(type: string): boolean {
    return !!this.acceptedFileTypes.find(
      (_type) => MimeTypes[_type.toLocaleLowerCase() as TMimeTypes] === type.toLocaleLowerCase()
    )
  }

  removeFile(i: number) {
    this._files.splice(i, 1)
    if (this.multiple) {
      this.file.emit(this._files)
    } else {
      this.file.emit(null)
    }
  }

  toggleHover(hover: boolean): void {
    this.hover = hover
  }

  fileSizeValid(file: File): boolean {
    const filesize = Math.floor(file.size / 1000000) //  MB
    if (this.maxFileSize && filesize > this.maxFileSize) {
      this.alertService.openAlert({
        title: 'ERRORS.fileSize',
        state: 'error'
      })
      return false
    }
    return true
  }

  fileTypeIsValid(file: File): Promise<boolean> {
    const isAcceptableFileType = this.isAcceptebleFileType(file.type)
    if (!isAcceptableFileType) {
      this.alertService.openAlert({ title: 'ERRORS.fileType', state: 'error' })
      return Promise.resolve(isAcceptableFileType)
    }
    return Promise.resolve(true)
  }

  getFileMimeType(file: File): string {
    return file.type.split('/')[1]
  }
}
