<div
  [ngClass]="{ hover: hover }"
  class="upload"
  id="target"
  (dragenter)="toggleHover(true)"
  (dragexit)="toggleHover(false)"
  (dragend)="toggleHover(false)"
  (drop)="toggleHover(false)"
  (dragleave)="toggleHover(false)"
>
  <input
    id="fileInput"
    type="file"
    [attr.aria-label]="'LABEL.dropfile' | translate"
    [(ngModel)]="files"
    (change)="onUpload($event)"
    [multiple]="multiple"
  />
  <div class="display">
    <div>
      <img src="/assets/img/upload-icon.svg" alt="" />
    </div>
    <div>
      <div class="oeo-label">{{ 'LABEL.dropFile' | translate }}</div>
      <button mat-raised-button color="primary" type="button" aria-describedby="fileInput">
        {{ 'LABEL.browseFiles' | translate }}
      </button>
    </div>
  </div>
  <p *ngIf="acceptedFileTypes">{{ 'LABEL.acceptedFileTypes' | translate }}: {{ formattedFileTypes }}</p>
  <p *ngIf="maxFileSize">{{ 'LABEL.maxFileSize' | translate }}: {{ maxFileSize }} MB</p>
</div>

<p *ngIf="_files.length > 0">
  <span class="label">{{ 'LABEL.uploadedFile' | translate }}:</span>
  <mat-chip-listbox>
    <mat-chip-option
      *ngFor="let file of _files; let i = index"
      [removable]="true"
      [selectable]="true"
      (removed)="removeFile(i)"
    >
      {{ file?.name }}
      <mat-icon matChipRemove svgIcon="close"></mat-icon>
    </mat-chip-option>
  </mat-chip-listbox>
</p>
