export class CustomerAccount {
  accountId!: string
  accountNumber!: string
  customerName!: string
  sectorAccountNumber!: string
  holdingAccountId!: string
  holdingAccountNumber!: string
  holdingSectorNumber!: string
  primaryCity!: string
  primaryState!: string
  primaryCountry!: string
  existsAmericas!: boolean
  existsEmeia!: boolean
  existsApac!: boolean
  business!: string
  customerDisplayName: string
  defaultBranch!: boolean
  constructor(customerAccount: Partial<CustomerAccount>) {
    Object.assign(this, customerAccount)
    this.customerDisplayName = `${customerAccount.customerName} - ${customerAccount.primaryCity}, ${customerAccount.primaryState}`
  }
}
