<div class="lib-prep-container">
  <form #PrepFieldsForm="ngForm" class="left">
    <mat-form-field class="no-hint">
      <mat-label>{{title}}</mat-label>
      <input
        matInput
        [ngModel]="getDisplayValue(code)"
        (ngModelChange)="setPrepCode($event)"
        [matAutocomplete]="stikePrepAuto"
        #strikePrepInput
        required
        [libValidValueValidator]="codes"
        validatorTransformFn="preps"
        name="strikePrepCode"
      />
      <mat-autocomplete autoActiveFirstOption #stikePrepAuto="matAutocomplete">
        <mat-option *ngFor="let option of codes | libAutoCompleteFilter:strikePrepInput.autocompleteValue:'preps'" [value]="getDisplayValue(option)">
          {{ getDisplayValue(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="no-hint">
      <mat-label>{{'FORMFIELDS.location' | translate}}</mat-label>
      <input
        matInput
        [(ngModel)]="prep.locationType"
        [matAutocomplete]="locationAutocomplete"
        #strikePrepInput
        required
        [libValidValueValidator]="locations"
        validatorTransformFn="value"
        name="strikePrepLocation"
      />
      <mat-autocomplete autoActiveFirstOption #locationAutocomplete="matAutocomplete">
        <mat-option *ngFor="let option of locations | libAutoCompleteFilter:strikePrepInput.autocompleteValue:'value'" [value]="option.value">
          {{ option.value }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="no-hint">
      <mat-label>{{'FORMFIELDS.referencePoint' | translate}}</mat-label>
      <input
        matInput
        [(ngModel)]="prep.referencePoint"
        [matAutocomplete]="referencePointAuto"
        #strikePrepInput
        required
        [libValidValueValidator]="referencePoints"
        validatorTransformFn="none"
        name="strikePrepReferencePoint"
      />
      <mat-autocomplete autoActiveFirstOption #referencePointAuto="matAutocomplete">
        <mat-option *ngFor="let option of referencePoints | libAutoCompleteFilter:strikePrepInput.autocompleteValue" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <lib-special-hardware [component]="this" [isSpecialPrep]="isSpecialPrep"></lib-special-hardware>
  </form>
  <lib-door-prep *ngIf="door" [door]="door" [prepLocationInputsFilled]="prepLocationInputsFilled"></lib-door-prep>
</div>
