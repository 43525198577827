<div class="header">
  <img src="assets/img/allegion-banner.png" alt="Allegion Banner" width="250px" />
  <button mat-icon-button class="close" [mat-dialog-close]="true">
    <span class="material-symbols-outlined"> close </span>
  </button>
</div>
<div mat-dialog-content #helpAndSupport>
  <div class="option" *ngIf="resourseLink$ | async">
    <div>{{ 'helpAndSupport' | translate }}</div>
    <a [href]="resourseLink$ | async" target="_blank">{{ 'ACTIONS.zendeskHelpCenter' | translate }}</a>
  </div>
  <div class="option" *ngIf="adSystems">
    <div>{{ 'adsSupport' | translate }}</div>
    <div>1-425-740-6011</div>
  </div>
  <div class="option">
    <div>{{ 'reportAProblem' | translate }}</div>
    <div class="button" (click)="openZenDeskHelpCenter()">{{ 'ACTIONS.createTicket' | translate }}</div>
  </div>
</div>
