<div class="lib-prep-container">
  <div class="left">
    <mat-form-field class="no-hint">
      <mat-label>{{ name }} Qty (Per Stick)</mat-label>
      <mat-select [(ngModel)]="quantity">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let c of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="no-hint">
      <mat-label>Junction Box</mat-label>
      <mat-select [value]="junctionBox" (selectionChange)="junctionBox = $event" [disabled]="!quantity">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let c of junctionBoxes" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <lib-input-option
      label="Location *"
      [selectedItemValue]="location"
      (selectionChange)="location = $event"
      [disabled]="!quantity"
      transformType="value"
      [optionsList]="locations"
    >
    </lib-input-option>
    <mat-form-field class="no-hint" label="">
      <mat-label>Reference Point *</mat-label>
      <mat-select [value]="referencePoint" (selectionChange)="referencePoint = $event" [disabled]="!quantity">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let c of referencePoints" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <lib-special-hardware [component]="this" [isSpecialPrep]="isSpecialPrep"></lib-special-hardware>
  </div>
  <lib-frame-prep
    *ngIf="frameElevation"
    [frameElevation]="frameElevation"
    [subTypes]="subTypes"
    [prepLocationInputsFilled]="prepLocationInputsFilled"
  >
  </lib-frame-prep>
</div>
