//add function that takes array and element and checks if it exists in array. If it doesn't add it
import { isEqual, clone } from 'lodash'

export function addIfNotExists<T>(inputArray: Array<T>, element: any) {
  const arrayClone = clone(inputArray)
  if (!inputArray.find((e) => isEqual(e, element))) {
    arrayClone.push(element)
  }
  return arrayClone
}
