<mat-card>
  <mat-card-content>
    <h3>{{ 'TITLE.defaultPOTermsAndConditions' | translate }}</h3>
    <form [formGroup]="form">
      <mat-form-field class="full-width text-area">
        <mat-label>{{ 'POTermsAndConditionsInformation' | translate }}</mat-label>
        <textarea
          #terms
          matInput
          rows="7"
          formControlName="purchaseOrderTerms"
          (blur)="updateTerms(terms.value)"
        ></textarea>
        <mat-hint [ngClass]="{ warning: terms.value.length > 1990 }">{{ terms.value.length }} / 2000</mat-hint>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>
