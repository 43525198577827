<lib-dialog
  header="{{ header }}"
  [closeable]="true"
  [ngClass]="{ preps: step === 1 }"
  [footerTemplate]="footerTemplate"
>
  <form [formGroup]="formGroup" autocomplete="off" *ngIf="step === 0; else FramePreps">
    <div class="row">
      <span>{{'INSTRUCTIONS.allRequiredFields'|translate}}</span>
    </div>
    <div class="row section-header">
      <span>{{'unitsOfMeasurement'|translate}}</span>
    </div>
    <mat-radio-group
      (change)="unitOfMeasure.patchValue($event.value)"
      [value]="unitOfMeasure.value"
      [disabled]="unitOfMeasure.disabled"
    >
      <mat-radio-button *ngFor="let value of unitOfMeasures" [value]="value">{{ value }}</mat-radio-button>
    </mat-radio-group>
    <div class="row section-header">
      <span>{{'dimensions'|translate}}</span>
    </div>
    <div class="row">
      <span>{{'INSTRUCTIONS.decimalOrFraction' | translate}}</span>
    </div>
    <div class="row padding top s4">
      <div class="col grow">
        <mat-form-field class="no-hint">
          <mat-label>{{'FORMFIELDS.widthOA' | translate}}</mat-label>
          <input
            matInput
            libApplyMeasure
            [formControl]="width"
            [unitOfMeasure]="unitOfMeasure.value"
          />
        </mat-form-field>
      </div>
      <div class="col grow">
        <mat-form-field class="no-hint">
          <mat-label>{{ 'FORMFIELDS.heightOA' | translate }}</mat-label>
          <input
            matInput
            libApplyMeasure
            [formControl]="height"
            [unitOfMeasure]="unitOfMeasure.value"
          />
        </mat-form-field>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.doorThickness' | translate"
          [disabled]="doorThickness.disabled"
          [selectedItemValue]="doorThickness.value"
          (selectionChange)="updateForm(doorThickness, $event)"
          [required]="required(doorThickness)"
          [optionsList]="doorThicknesses"
        ></lib-input-option>
      </div>
    </div>
    <div class="row section-header">
      <span>{{'TITLE.frameSettings' | translate}}</span>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.series' | translate"
          [disabled]="series.disabled"
          [selectedItemValue]="series.value"
          (selectionChange)="updateForm(series, $event)"
          [required]="required(series)"
          [optionsList]="serieses"
        ></lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.template' | translate"
          [disabled]="templateType.disabled"
          [selectedItemValue]="templateType.value"
          (selectionChange)="updateForm(templateType, $event)"
          [required]="required(templateType)"
          [optionsList]="templateTypes"
        >
          <ng-template let-option="option" #optionTemplate>
            <div class="style">
              <img src="{{ environment.elevations.blobUrl + 'templates/' + option + '.svg' }}" /><span>{{
                option
              }}</span>
            </div>
          </ng-template>
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.fireRating' | translate"
          [disabled]="fireRating.disabled"
          [selectedItemValue]="fireRating.value"
          (selectionChange)="updateForm(fireRating, $event)"
          [required]="required(fireRating)"
          [optionsList]="fireRatings"
        ></lib-input-option>
      </div>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.additionalLabel' | translate"
          [disabled]="additionalLabel.disabled"
          [selectedItemValue]="additionalLabel.value"
          (selectionChange)="updateForm(additionalLabel, $event)"
          [required]="required(additionalLabel)"
          [optionsList]="additionalLabels"
        ></lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.frameType' | translate"
          [disabled]="approval.disabled"
          [selectedItemValue]="approval.value"
          (selectionChange)="updateForm(approval, $event)"
          [required]="required(approval)"
          [optionsList]="approvals"
        ></lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.metalOrMylar' | translate"
          [disabled]="metalMylar.disabled"
          [selectedItemValue]="metalMylar.value"
          (selectionChange)="updateForm(metalMylar, $event)"
          [required]="required(metalMylar)"
          [optionsList]="metalMylars"
        ></lib-input-option>
      </div>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.jambDepth' | translate"
          [disabled]="jambDepth.disabled"
          [selectedItemValue]="jambDepth.value"
          (selectionChange)="updateForm(jambDepth, $event)"
          [required]="required(jambDepth)"
          [optionsList]="jambDepths"
        ></lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.gauge' | translate"
          [disabled]="gauge.disabled"
          [selectedItemValue]="gauge.value"
          (selectionChange)="updateForm(gauge, $event)"
          [required]="required(gauge)"
          [optionsList]="gauges"
        ></lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.material' | translate"
          [disabled]="material.disabled"
          [selectedItemValue]="material.value"
          (selectionChange)="updateForm(material, $event)"
          [required]="required(material)"
          [optionsList]="materials"
        ></lib-input-option>
      </div>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.cornerCondition' | translate"
          [disabled]="cornerCondition.disabled"
          [selectedItemValue]="cornerCondition.value"
          (selectionChange)="updateForm(cornerCondition, $event)"
          [required]="required(cornerCondition)"
          [optionsList]="cornerConditions"
        ></lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.cornerAssembly' | translate"
          [disabled]="cornerAssembly.disabled"
          [selectedItemValue]="cornerAssembly.value"
          (selectionChange)="updateForm(cornerAssembly, $event)"
          [required]="required(cornerAssembly)"
          [optionsList]="cornerAssemblies"
        ></lib-input-option>
      </div>
      <div class="col grow"></div>
    </div>
    <div class="row section-header">
      <span>{{'TITLE.glass'|translate}}</span>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.beadSize' | translate"
          [disabled]="glazingBeadSize.disabled"
          [selectedItemValue]="glazingBeadSize.value"
          (selectionChange)="updateForm(glazingBeadSize, $event)"
          [required]="required(glazingBeadSize)"
          [optionsList]="glazingBeadSizes"
        ></lib-input-option>
      </div>

      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.location' | translate"
          [disabled]="glazingBeadLocation.disabled"
          [selectedItemValue]="glazingBeadLocation.value"
          (selectionChange)="updateForm(glazingBeadLocation, $event)"
          [required]="required(glazingBeadLocation)"
          [optionsList]="glazingBeadLocations"
        ></lib-input-option>
      </div>

      <div class="col grow">
        <mat-form-field class="no-hint">
          <mat-label>{{ 'FORMFIELDS.thickness' | translate }}</mat-label>
          <input
            matInput
            libApplyMeasure
            [formControl]="glassThickness"
            [unitOfMeasure]="unitOfMeasure.value"
          />
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="row">
      <span class="section-header">Kerf</span>
    </div>
    <div class="row">
      <div class="col">
        <phx-radio-group [disabled]="kerf.disabled" (onChange)="kerf.patchValue($event)" [value]="kerf.value">
          <phx-radio-button [value]="false">No</phx-radio-button>
          <phx-radio-button [value]="true">Yes</phx-radio-button>
        </phx-radio-group></phx-col
      >
      <div class="col"></div>
      <div class="col"></div>
    </div> -->
    <!-- <div class="row">
      <span class="section-header">Anchors</span>
    </div>
    <div class="row">
      <div class="col">

          <span class="label">Base Anchor *</span>
          <phx-select
            [disabled]="baseAnchor.disabled"
            [selectedItemValue]="baseAnchor.value"
            (selectionChange)="updateForm(baseAnchor, $event)"
            [error]="baseAnchor.invalid && baseAnchor.touched"
          >
            <phx-option [value]="null">--</phx-option>
            <phx-option *ngFor="let o of baseAnchors" [value]="o">{{ o }}</phx-option>
          </phx-select>

      </div>
      <div class="col">

          <span class="label">Wall Anchor *</span>
          <phx-select
            [disabled]="wallAnchor.disabled"
            [selectedItemValue]="wallAnchor.value"
            (selectionChange)="updateForm(wallAnchor, $event)"
            [error]="wallAnchor.invalid && wallAnchor.touched"
          >
            <phx-option [value]="null">--</phx-option>
            <phx-option *ngFor="let o of wallAnchors" [value]="o">{{ o }}</phx-option>
          </phx-select>

      </div>
      <div class="col"></div>
    </div> -->

    <div class="row section-header">
      <span>{{'TITLE.framePreps'|translate}}</span>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.prepLocationPreference'|translate"
          [selectedItemValue]="prepLocationPreference.value"
          (selectionChange)="updateForm(prepLocationPreference, $event)"
          [optionsList]="prepLocationPreferences"
        >
        </lib-input-option>
      </div>
      <div class="col grow"></div>
      <div class="col grow"></div>
    </div>
  </form>
</lib-dialog>

<ng-template #footerTemplate>
  <div class="actions" *ngIf="step === 0">
    <mat-checkbox color="primary" [(ngModel)]="saveAsPreference">{{ 'saveAsPreference' | translate }}</mat-checkbox>
    <button
      class="action margin s6 left right"
      mat-stroked-button
      *ngIf="data.isNew"
      tabindex="1"
      (click)="dialogService.close()"
    >
      {{ 'BUTTON.cancel' | translate }}
    </button>
    <button
      class="action"
      tabindex="1"
      color="primary"
      mat-flat-button
      (click)="continue()"
      [disabled]="formGroup.invalid"
    >
      {{ (data.isNew && step === 0 ? 'BUTTON.continue' : 'BUTTON.finish') | translate }}
    </button>
  </div>
</ng-template>

<ng-template #FramePreps>
  <lib-preps
    [data]="{ frame: elevation, isNew: data.isNew }"
    (back$)="step = 0"
    (next$)="elevation = $event; continue()"
  >
  </lib-preps>
</ng-template>
