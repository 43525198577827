import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { CustomerService } from '../../../services/customer/customer.service'
import { AsyncSubject, Observable } from 'rxjs'
import { Customer } from '../../../models/customer.model'

@Injectable({
  providedIn: 'root'
})
export class CustomerServiceResolver {
  constructor(private customerService: CustomerService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<Customer> {
    const customer$: AsyncSubject<Customer> = new AsyncSubject()
    this.customerService.customer$.subscribe((customer) => {
      if (customer) {
        customer$.next(customer)
        customer$.complete()
      }
    })
    return customer$
  }
}
