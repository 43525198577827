<div class="lib-prep-container">
  <div class="left">
    <lib-input-option
      label="Location *"
      [selectedItemValue]="location"
      (selectionChange)="location = $event"
      [optionsList]="locations"
      transformType="value"
    >
    </lib-input-option>
    <!--  -->
    <mat-form-field class="no-hint">
      <mat-label>Reference Point *</mat-label>
      <mat-select
        [(ngModel)]="referencePoint"
        [disabled]="location !== specialPrepLocation"
      >
        <mat-option *ngFor="let c of referencePoints" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <lib-frame-prep
    *ngIf="frameElevation"
    [frameElevation]="frameElevation"
    [subTypes]="subTypes"
    [prepLocationInputsFilled]="prepLocationInputsFilled"
  >
  </lib-frame-prep>
</div>
