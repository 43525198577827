/* eslint-disable @typescript-eslint/member-ordering */
import { animate, style, transition, trigger } from '@angular/animations'
import { CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { cloneDeep, isEqual, sortBy } from 'lodash'
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs'
import {
  ActiveFilters,
  SortingAndPaginationEvent,
  ExpandTableEvent,
  Column,
  SelectColumn,
  TableColumn,
  TableConfig,
  SEARCH_AND_FILTER_SERVICE,
  ISearchAndFilterService,
  TableIcon
} from '../../types'
import { TableService } from './table.service'
import { TranslateService } from '@ngx-translate/core'
import { IStorageService, STORAGE_SERVICE } from '../../interfaces/i-storage-service'
import { isRecentlyAddedItem } from '../../helpers/recently-added-item'
import { TCurrencyComponentData } from './column-type-components/currency/currency.component'
import { TDateComponentData } from './column-type-components/date/date.component'
import { TDefaultComponentData } from './column-type-components/default/default.component'
import { TButtonComponentData } from './column-type-components/button/button.component'
import { TIconComponentData } from './column-type-components/icon/icon.component'
import { TInputComponentData } from './column-type-components/input/input.component'

type SortDirection = null | 'asc' | 'desc'

type BaseTableData = { selected: boolean; [index: string]: any; created?: string }

type TableData = BaseTableData &
  TCurrencyComponentData &
  TDateComponentData &
  TDefaultComponentData &
  TButtonComponentData &
  TIconComponentData &
  TInputComponentData

@Component({
  selector: 'oa-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      transition(':enter', [
        style({
          transform: 'scaleY(0)',
          'transform-origin': '50% 0',
          overflow: 'hidden'
        }),
        animate('250ms', style({ transform: 'scaleY(1)' }))
      ]),
      transition(':leave', [
        style({
          transform: 'scale(1)',
          'transform-origin': '50% 0',
          overflow: 'hidden'
        }),
        animate('250ms', style({ transform: 'scaleY(0)' }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() showRecentlyAddedItems = false

  recentCleared = false
  @HostListener('window:click', ['$event']) onWindowClick = () => (this.recentCleared = true)
  navigateToRecentlyAddedItems = false

  #data: TableData[] = []
  @Input() set data(data: TableData[]) {
    if (!data) return
    this.#data = data
    this.recentCleared = false
    this.navigateToRecentlyAddedItems = this.data?.some((row: TableData) => this.newItemAdded(row))
  }
  get data(): TableData[] {
    return this.#data
  }
  @Input() config!: TableConfig<TableData>
  @Input() count = 0
  @Input() loading = false
  @Input() filter = false
  @Input() useBackendFilter = false

  @ViewChild('table', { static: true }) tableRef!: ElementRef<HTMLDivElement>
  @ViewChild('thead', { static: true }) theadRef!: ElementRef<HTMLTableSectionElement>

  @Output() filtersChanged: EventEmitter<ActiveFilters> = new EventEmitter()
  @Output() booleanFiltersChanged: EventEmitter<{ [key: string]: boolean }> = new EventEmitter()
  @Output() searchChanged: EventEmitter<string> = new EventEmitter()

  tableRows!: any[]
  #tableColumns: TableColumn<TableData>[] = this.makeColumns()
  get tableColumns(): TableColumn<TableData>[] {
    return this.#tableColumns
  }
  set tableColumns(columns: TableColumn<TableData>[]) {
    this.#tableColumns = columns
  }
  searchValue$: Subject<string> = new Subject()

  _data!: TableData[]
  displayedData!: TableData[]
  showfilter = false
  selectAll = false

  //  Expandable
  expanded = false
  expandedRowIndex!: number

  searchTableValue!: string
  activeFilters: ActiveFilters = {}
  booleanFilters: { [key: string]: boolean } = {}
  filterParams: Record<string, string> = {}

  //  Sort & Paginate
  activeSort!: string
  params: SortingAndPaginationEvent = {
    tableName: this.config?.name ? this.config.name.toString() : '',
    skip: 0,
    pageSize: this.storageService.containsItem('pageLength') ? this.storageService.getItem('pageLength') : 15,
    property: null,
    direction: null
  }

  //  Pagination
  totalPages: number = Math.ceil((this.count || this.data.length) / this.params.pageSize)
  itemsPerPageOptions: number[] = [15, 25, 50, 100]
  optionsMinVal = Math.min.apply(null, this.itemsPerPageOptions)
  page = 1
  pageNumbers: number[] = this.getPageNumbers()
  pagination!: boolean
  start!: number
  end!: number

  private destroyed$: Subject<boolean> = new Subject()

  //  Translation
  of!: string
  items!: string
  pages!: string

  constructor(
    public tableService: TableService<TableData>,
    public cd: ChangeDetectorRef,
    public translateService: TranslateService,
    @Inject(SEARCH_AND_FILTER_SERVICE) public searchAndFilterService: ISearchAndFilterService,
    @Inject(STORAGE_SERVICE) public storageService: IStorageService<{ pageLength: number }>
  ) {}

  ngOnInit() {
    this.of = this.translateService.instant('of')
    this.items = this.translateService.instant('LABEL.items')
    this.pages = this.translateService.instant('LABEL.pages')

    this.searchAndFilterService.buttonClickTrackEvent.pipe(takeUntil(this.destroyed$)).subscribe((resetTableName) => {
      if (this.config.name === resetTableName) {
        this.updateFilterData()
      }
    })

    this.searchValue$
      .asObservable()
      .pipe(distinctUntilChanged(), debounceTime(250), takeUntil(this.destroyed$))
      .subscribe((res) => this.searchChanged.emit(res))
  }

  tableScroll = (event: Event) => {
    const translate = 'translate(0,' + this.tableRef.nativeElement.scrollTop + 'px'
    this.theadRef.nativeElement.style.transform = translate
  }

  ngAfterViewInit() {
    this.tableRef.nativeElement.addEventListener('scroll', this.tableScroll)
  }

  displayColumns!: SelectColumn[]

  showColumn(column: TableColumn<TableData>): boolean {
    // if property is set to false in table service, hide column, otherwise check if hiddenIfNoData is true
    if (this.tableService.columnVisibilitySet(this.config.name, column.property?.toString())) {
      return this.tableService.isColumnVisible(this.config.name, column.property?.toString())
    }
    return !(column.hideIfNoData && !column.hasData)
  }

  onDisplayColumnsUpdated(displayColumns: SelectColumn[]) {
    this.displayColumns = displayColumns
    displayColumns.forEach(({ property, show }) => {
      /* Clear filter value if column is hidden */
      if (!show) {
        const columnIndex = this.tableColumns.findIndex((col) => col.property?.toString() === property)
        if (columnIndex > -1) {
          this.tableColumns[columnIndex].filterValue = ''
        }
        if (this.useBackendFilter) {
          this.filterTableBackend(property, '')
        } else {
          this.filterTable()
        }
      }
      this.tableService.setColumnVisibility(this.config.name, property, show)
    })
  }

  ngOnChanges(event: SimpleChanges) {
    if (
      event['config'] &&
      (event['config'].firstChange || !isEqual(event['config'].previousValue, event['config'].currentValue))
    ) {
      this.selectAll = false
      this.tableColumns = this.makeColumns()
      this.pagination = event['config']?.currentValue?.pagination === false ? false : true
      this.params.tableName = this.config.name ? this.config.name.toString() : ''
      this.displayColumns = this.tableColumns
        .filter((tc) => !!tc.header)
        .map((tc) => {
          return {
            name: tc.header,
            property: tc.property?.toString(),
            show: this.showColumn(tc),
            visibleAlways: tc.visibleAlways
          } as SelectColumn
        })
    }

    if (event['data']) {
      if (
        event['data'].firstChange ||
        !event['data'].previousValue ||
        !event['data'].currentValue ||
        event['data'].currentValue.length !== event['data'].previousValue.length
      ) {
        this._data = cloneDeep(event['data'].currentValue || [])
      } else {
        event['data'].currentValue.forEach((element: any, index: number) => {
          if (!isEqual(element, event['data']?.previousValue[index])) {
            this._data[index] = element
          }
        })
      }
      if (this.data) {
        this.totalPages = Math.ceil((this.count || this.data.length) / this.params.pageSize)
        this.pageNumbers = this.getPageNumbers()
        this.displayedData = this.paginateData(this._data)
        if (this.navigateToRecentlyAddedItems) {
          this.updatePage(this.totalPages)
        }
      }
    }
  }

  makeColumns() {
    if (this.config) {
      return this.config.columns.map((column: Column<TableData>) => new TableColumn(column, this.config.name))
    }
    return []
  }

  /**
   * Gets data - allows for nested properties
   *
   * @param row
   * @param property
   * @returns
   */
  getData(row: TableData, property: Column<TableData>['property']): string | number | boolean {
    if (!row) return ''
    if (typeof property === 'string') {
      const keys = property.split('.')
      let data = row
      keys.forEach((key: string) => {
        if (data) data = data[key]
      })
      return data as unknown as string
    } else if (typeof property === 'function') {
      return property(row)
    }
    return ''
  }

  hasData(row: TableData, property: Column<TableData>['property']): boolean {
    if (!row) return false
    if (typeof property === 'string') {
      return !!row[property]
    }
    return false
  }

  getMidNoteData(row: TableData, property: Column<TableData>['property']): any {
    if (!row) return ''
    if (typeof property === 'string') {
      const keys = property.split('.')
      let data = row
      keys.forEach((key: string) => {
        if (data) data = data[key]
      })
      return data
    }
    return ''
  }

  newItemAdded<T extends TableData>(row: T) {
    if (!row.created) return false
    const createdLessThan30sAgo = isRecentlyAddedItem(row)
    if (createdLessThan30sAgo) {
      return this.showRecentlyAddedItems && !this.recentCleared
    } else return false
  }

  // table Outputs
  handleRowClick(event: any, index: number): void {
    const payload = { ...event }
    if (this.config.radioButtons && !event.selected && !this.config.radioDisabled) {
      payload.selected = !event.selected
      this.handleRadioChange(event, payload, index)
    }
    this.tableService.rowClickEvent.next({
      tableName: this.config.name,
      data: payload
    })
  }

  onCheckboxChange(event: any) {
    this.selectAll = false
    const row = { ...event }
    this.tableService.rowClickEvent.next({ tableName: this.config.name, data: row })
  }

  onSelectAll() {
    if (this.selectAll) {
      this.displayedData.forEach((row) => {
        if (row.selected) {
          return
        }
        row.selected = true
        const _row = { ...row }
        this.tableService.rowClickEvent.next({
          tableName: this.config.name,
          data: _row
        })
      })
    } else {
      this.displayedData.forEach((row) => {
        if (!row.selected) {
          return
        }
        row.selected = false
        const _row = { ...row }
        this.tableService.rowClickEvent.next({
          tableName: this.config.name,
          data: _row
        })
      })
    }
  }

  dragItem(event: CdkDragSortEvent) {
    moveItemInArray(this.displayedData, event.previousIndex, event.currentIndex)
    this.tableService.reorderEvent.next({
      tableName: this.config.name,
      previousIndex: event.previousIndex + (this.page - 1) * this.params.pageSize,
      currentIndex: event.currentIndex + (this.page - 1) * this.params.pageSize
    })
  }

  updateFilterData() {
    this.displayedData = this.data
    this.filterParams = {}
    if (this.tableColumns) {
      this.tableColumns.forEach((col) => {
        col.filterValue = ''
        if (!col.property) return
        if (this.activeFilters[col.property.toString()]) {
          delete this.activeFilters[col.property.toString()]
        }
        const activeFiltersIcons = this.getActiveFilterIcons(col)
        if (activeFiltersIcons.length > 0) {
          activeFiltersIcons.forEach((iconFilter) => {
            if (iconFilter.filterKey) {
              delete this.booleanFilters[iconFilter?.filterKey]
            }
          })
        }
      })
    }
    this.filtersChanged.emit(this.activeFilters)
    this.booleanFiltersChanged.emit(this.booleanFilters)
    this.cd.markForCheck()
  }

  filterTableBackend(columnProperty: string | ((row: any) => any) | undefined = '', filterValue: string = '') {
    if (filterValue && filterValue.length >= 2) {
      this.activeFilters[columnProperty.toString()] = filterValue
      this.filtersChanged.emit(this.activeFilters)
    } else if (this.activeFilters[columnProperty.toString()] && filterValue.length < 1) {
      delete this.activeFilters[columnProperty.toString()]
      this.filtersChanged.emit(this.activeFilters)
    }
  }

  getActiveFilterIcons(col: TableColumn<TableData>): TableIcon[] {
    if (!col?.icons) return []
    return col.icons.filter((icon) => {
      if (!icon?.filterKey) return false
      if (this.booleanFilters[icon?.filterKey]) {
        return true
      }
      return false
    })
  }

  setActiveIconFilter(filterKey: string = '', isActive: boolean = false) {
    if (filterKey.length === 0) return
    if (isActive) {
      this.booleanFilters[filterKey] = true
    } else {
      delete this.booleanFilters[filterKey]
    }
    this.booleanFiltersChanged.emit(this.booleanFilters)
  }

  filterTable() {
    //grab value and col names from config and assign to dictionary to pass in
    this.tableColumns.forEach((column) => {
      if (!column.property || !column.filterValue) return
      this.filterParams[column?.property?.toString()] = column.filterValue
    })

    this.displayedData = this.searchAndFilterService.searchAndFilter(
      this.activeFilters,
      this.data,
      [],
      this.searchTableValue,
      this.filterParams
    )

    this.cd.markForCheck()
  }

  displaySecondaryColumn(primaryColumn: TableColumn<TableData>, item: TableData) {
    return primaryColumn?.secondaryColumnConfig?.checkFn ? primaryColumn?.secondaryColumnConfig?.checkFn(item) : false
  }

  onRadioChange(event: any, index: number) {
    const payload = { ...event }
    payload.selected = !event.selected
    this.handleRadioChange(event, payload, index)
    this.tableService.rowClickEvent.next({
      tableName: this.config.name,
      data: payload
    })
  }

  handleRadioChange(event: any, payload: any, index: number) {
    this.displayedData.forEach((row) => (row.selected = false))
    this.displayedData[index].selected = !event.selected
  }

  // Expanded

  getSpan(): number {
    let span = 0
    span += this.config.columns.length
    span += this.config.checkboxes ? 1 : 0
    span += this.config.expandable ? 1 : 0
    if (this.config.columns.some((c) => c.displayInFullRow)) {
      span -= this.config.columns.filter((c) => c.displayInFullRow).length
    }
    return span
  }

  createTableColumn<T>(column: TableColumn<T>) {
    return new TableColumn<T>(column.secondaryColumnConfig, this.config.name)
  }

  onToggleExpand(row: any, index: number) {
    if (index === this.expandedRowIndex) {
      this.expanded = !this.expanded
    } else {
      //  let expanded close before opening a new one
      if (this.expanded) {
        this.expanded = false
        setTimeout(() => {
          this.expanded = true
          this.expandedRowIndex = index
          this.cd.markForCheck()
        }, 250)
      } else {
        this.expanded = true
        this.expandedRowIndex = index
      }
    }
    this.tableService.expandTable.next({
      tableName: this.config.name,
      expanded: this.expanded,
      data: row,
      expandedRowIndex: index
    } as ExpandTableEvent)
  }

  sortAndPaginate(): TableData[] {
    let data = this._data
    this.expanded = false
    data = this.sort(data)
    data = this.paginateData(data)
    return data
  }

  //  Sort

  getAriaLabel(col: TableColumn<TableData>) {
    let sortDirection: string
    if (!this.params.direction || col.property !== this.params.property) {
      sortDirection = 'ascending'
    } else {
      sortDirection = this.params.direction === 'asc' ? 'descending' : 'initial'
    }
    return `Sort ${col.header} in ${sortDirection} order`
  }

  sortColumn(sortable: boolean = true, property: string = ''): void {
    if (sortable === false) {
      return
    }
    if (property !== this.activeSort) {
      this.activeSort = property
      this.params.direction = this.setDirection(null)
      this.params.property = property
    } else {
      this.params.direction = this.setDirection(this.params.direction)
    }
    this.page = 1
    const params: SortingAndPaginationEvent = {
      tableName: this.config.name ? this.config.name.toString() : '',
      skip: (this.page - 1) * this.params.pageSize,
      pageSize: this.params.pageSize,
      property: property,
      direction: this.params.direction
    }
    this.tableService.sortingAndPaginationEvent.next(params)
    if (!this.config.paginationType || this.config.paginationType === 'front-end') {
      this.displayedData = this.sortAndPaginate()
    }
  }

  setDirection(direction: SortDirection): SortDirection {
    switch (direction) {
      case 'asc':
        return 'desc'
      case 'desc':
        return null
      default:
        return 'asc'
    }
  }

  sort(data: TableData[]): TableData[] {
    this.expanded = false
    if (this.config.paginationType === 'back-end' || !this.params.direction || !this.params.property) {
      return data
    }
    let sorted = sortBy(data, (element) => {
      if (!this.params.property) return ''
      const properties = this.params.property?.split('.')
      let value = element
      properties.forEach((property) => {
        value = value[property]
      })
      return (value ?? '')['toUpperCase']()
    })
    if (this.params.direction === 'desc') {
      sorted = sorted.reverse()
    }
    return sorted
  }

  //  Pagination
  getPageNumbers(): number[] {
    let i = 1
    const pageNumbers: number[] = []
    while (i <= this.totalPages) {
      pageNumbers.push(i)
      i++
    }
    return pageNumbers
  }

  getCurrentPage(): string {
    return `${this.page} ${this.of} ${this.totalPages} ${this.pages}`
  }

  getPagePosition(): string {
    const dataLength = this.count || this.data.length
    this.start = (this.page - 1) * this.params.pageSize + 1
    this.end = this.params.pageSize > dataLength ? dataLength : this.start + this.params.pageSize - 1
    return `${this.start} - ${this.end < dataLength ? this.end : dataLength} ${this.of} ${dataLength} ${this.items}`
  }

  paginateData(data: TableData[]) {
    const dataLength = this.count || this.data.length
    this.start = (this.page - 1) * this.params.pageSize
    this.end = this.params.pageSize + this.start
    this.expanded = false
    if (
      this.config.pagination !== false &&
      this.config.paginationType !== 'back-end' &&
      dataLength > this.optionsMinVal
    ) {
      return data.slice(this.start, this.end)
    } else {
      return data
    }
  }

  updatePageLength(event: number): void {
    const dataLength = this.count || this.data.length
    this.params.pageSize = event
    this.totalPages = Math.ceil(dataLength / this.params.pageSize)
    this.pageNumbers = this.getPageNumbers()
    this.getCurrentPage()
    this.storageService.setItem('pageLength', event)
    this.displayedData = this.sortAndPaginate()
    this.params.skip = (this.page - 1) * event
    this.tableService.sortingAndPaginationEvent.next(this.params)
  }

  updatePage(event: number): void {
    this.page = event
    this.displayedData = this.sortAndPaginate()
    this.params.skip = (event - 1) * this.params.pageSize
    this.tableService.sortingAndPaginationEvent.next(this.params)
  }

  decrementPage(): void {
    this.selectAll = false
    this.page -= 1
    this.displayedData = this.sortAndPaginate()
    this.params.skip = (this.page - 1) * this.params.pageSize
    this.tableService.sortingAndPaginationEvent.next(this.params)
  }

  incrementPage(): void {
    this.selectAll = false
    this.page += 1
    this.displayedData = this.sortAndPaginate()
    this.params.skip = (this.page - 1) * this.params.pageSize
    this.tableService.sortingAndPaginationEvent.next(this.params)
  }

  stopPropagation(event: Event) {
    event.stopPropagation()
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
    this.tableRef.nativeElement.removeEventListener('scroll', this.tableScroll)
  }
}
