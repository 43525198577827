import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ISupportTicket } from '../interfaces/i-support-ticket'
import { Injectable, inject } from '@angular/core'
import { ICommonEnvironment, COMMON_ENVIRONMENT_TOKEN } from '../injection-tokens'

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  environment = inject<ICommonEnvironment>(COMMON_ENVIRONMENT_TOKEN)
  http = inject(HttpClient)

  public sendSupportTicket(supportTicket: ISupportTicket) {
    const url = this.environment.onlineOrderingApiUrl + 'Support/SendSupportTicket'
    const formData = new FormData()

    Object.keys(supportTicket).forEach((key) => {
      if (key === 'attachments' && Array.isArray(supportTicket.attachments)) {
        supportTicket.attachments?.forEach((attachment) => {
          formData.append('attachments', attachment)
        })
        return
      }
      const value = supportTicket[key as keyof Omit<ISupportTicket, 'attachments'>]
      if (value) {
        formData.append(key, value)
      }
    })

    const headers = new HttpHeaders()
    return this.http.post<boolean>(url, formData, { headers })
  }
}
