<div class="lib-prep-container">
  <form #PrepFieldsForm="ngForm" class="left">
    <mat-form-field class="no-hint">
      <mat-label>{{'FORMFIELDS.flushBolt'|translate}}</mat-label>
      <input
        matInput
        [ngModel]="getDisplayValue(code)"
        (ngModelChange)="setPrepCode($event)"
        [matAutocomplete]="auto"
        #flushBoltPrepInput
        required
        [libValidValueValidator]="prepCodes"
        validatorTransformFn="preps"
        name="flushBoltPrepCode"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of prepCodes | libAutoCompleteFilter:flushBoltPrepInput.autocompleteValue:'preps'"
          [value]="getDisplayValue(option)"
        >
          {{ getDisplayValue(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <lib-door-prep *ngIf="door" [door]="door" [prepLocationInputsFilled]="prepLocationInputsFilled"></lib-door-prep>
</div>
