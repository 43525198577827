import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'

export interface DeactivatableComponent {
  canDeactivate: (args?: any) => Observable<boolean> | Promise<boolean> | boolean
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard  {
  canDeactivate(
    component: DeactivatableComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    const destination = nextState.url.match(/(\/elevations\/).*/g)
    if (!destination) return true
    const paths =
      //ETO: root -> Estimate -> EstimateID -> Elevations -> children
      currentState?.root?.firstChild?.children[0]?.firstChild?.routeConfig.children ??
      //Elevations UI: root -> Elevations -> children
      currentState?.root?.children[0]?.routeConfig?.children ??
      []
    const destinationPath = paths.find(({ path }) => path === destination[0]) ?? ''
    return component?.canDeactivate ? component.canDeactivate(destinationPath) : true
  }
}
