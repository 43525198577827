import { Component, inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import { MatButtonModule } from '@angular/material/button'
import { TranslateModule } from '@ngx-translate/core'
import { MatIconModule } from '@angular/material/icon'
import { AlertData } from '../../types/alert.model'
import { AlertService } from '../../services/alert.service'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'oa-alert',
  template: `<div [class]="'container alert-' + data.state">
    <span class="icon">
      <span class="material-symbols-outlined"> {{ alertIcons[data.state] }} </span>
    </span>
    <div class="content">
      <span class="alert-title">{{ data.title | translate }}</span>
      <span class="alert-description" *ngIf="data?.description">{{ data.description ?? '' | translate }}</span>
    </div>
    <span class="snackbar-actions">
      <button mat-button color="primary" *ngIf="data?.secondaryAction" (click)="data.secondaryAction?.action()">
        {{ data.secondaryAction?.label ?? '' | translate }}
      </button>
      <button mat-icon-button (click)="alertService.closeAlert()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </span>
  </div>`,
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [MatButtonModule, TranslateModule, MatIconModule, CommonModule]
})
export class AlertComponent {
  data = inject<AlertData>(MAT_SNACK_BAR_DATA)
  alertService = inject(AlertService)

  alertIcons = {
    error: 'error',
    success: 'check_circle',
    warning: 'warning',
    info: 'info'
  }
}
