import { Component, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { EMPTY, Subject, switchMap, takeUntil } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { Announcement, Features } from '../../../models'
import { AnnouncementsService } from '../../../services/announcements/announcements.service'
import { CustomerService } from '../../../services/customer/customer.service'
import { FlagsService } from '../../../services/flags/flags.service'
import { HelperService } from '../../../services/helper/helper.service'
import { StorageService } from '../../../services/local-storage/local-storage.service'
import { CustomerAccount } from '@oeo/common'
interface ResourceLink {
  link: string
  text: string
}

@Component({
  selector: 'oa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  destroyed$: Subject<boolean> = new Subject()

  flags: Features
  resourceLinks: ResourceLink[] = []
  new: number
  spanishEnabled = environment.featureFlags.enableSpanish

  get showBreadCrumbs(): boolean {
    return (
      (this.flags?.enableMultiBranch && this.customerService.customer != null) ||
      this.storageService.containsItem('parentCompanyId')
    )
  }

  get interalUser(): boolean {
    return this.storageService.getItem('internal')
  }

  constructor(
    public dialog: MatDialog,
    public flagsService: FlagsService,
    public customerService: CustomerService,
    private announcementsService: AnnouncementsService,
    private storageService: StorageService,
    public helperService: HelperService
  ) {
    this.flagsService.flags$
      .pipe(
        switchMap((flags) => {
          this.flags = flags
          if (!flags) {
            return EMPTY
          }
          this.resourceLinks = this.createResourceLinks(flags)
          return this.customerService.unauthorized$
        }),
        switchMap((unauthorized) => {
          if (unauthorized) {
            return EMPTY
          }
          return this.announcementsService.get(true)
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((announcements) => {
        if (!announcements) {
          return
        }

        this.new = announcements ? announcements.filter((a: Announcement) => a.isNew).length : 0
      })
  }

  #targetBranch: CustomerAccount = this.customerService.selectedBranch

  set targetBranch(value: CustomerAccount) {
    this.#targetBranch = value
    this.customerService.parentCompany = value
  }

  get targetBranch(): CustomerAccount {
    return this.customerService.selectedBranch ?? this.#targetBranch
  }

  selectCompany(company: CustomerAccount) {
    this.customerService.parentCompany = company
    this.targetBranch = company
    this.customerService.selectBranch(company.accountId)
  }

  createResourceLinks(flags: Features): ResourceLink[] {
    const resourceLinks: ResourceLink[] = []
    if (flags.isDirect) {
      resourceLinks.push(
        {
          link: 'https://us.allegion.com/en/home/markets/multifamily.html',
          text: 'RESOURCES.allegionMultifamilyWebsite'
        },
        {
          link: 'https://us.allegion.com/content/dam/allegion-us-2/web-documents-2/Catalog/Allegion_Multi-Family_Suiting_Solutions_Catalog_111390.pdf', // tslint:disable-line
          text: 'RESOURCES.multiFamilyGuide'
        },
        {
          link: 'https://www.youtube.com/user/allegionus/playlists',
          text: 'RESOURCES.multifamilyInstallationVideosPlaylist'
        },
        {
          link: 'https://www.youtube.com/watch?v=wAa19XXWnX0',
          text: 'RESOURCES.schlageControlLeNdeVideo'
        },
        {
          link: 'https://us.allegion.com/en/home/alliances.html',
          text: 'RESOURCES.softwareAllianceMemberlist'
        },
        {
          link: 'https://us.allegion.com/content/dam/allegion-us-2/web-documents-2/Brochure/Schlage_Control_LE_NDE_CTE_with_ENGAGE_Brochure_111494.pdf', // tslint:disable-line
          text: 'RESOURCES.engageBrochure'
        },
        {
          link: 'https://www.allegion.com/content/dam/us-res-mktg/PDF-thumbnails/PDF-low/MR-4073.pdf',
          text: 'RESOURCES.resQuickReferenceGuide'
        },
        {
          link: 'https://360portal.allegion.com/sso',
          text: 'RESOURCES.360Portal'
        }
      )
      if (flags.country !== 'CAN')
        resourceLinks.push(
          {
            link: 'https://us.allegion.com/content/dam/allegion-us-2/web-documents-2/DataSheet/Allegion_ProExpress_Multi_Family_Sell_Sheet_SKUs_112274.pdf', // tslint:disable-line
            text: 'RESOURCES.proExpressMultifamilyItemList'
          },
          {
            link: 'https://kc.allegion.com/kb/article/proexpress-program-guidelines/',
            text: 'RESOURCES.proExpressProgramInformation'
          }
        )
    }
    if (flags.isIndirect) {
      resourceLinks.push(
        {
          link: 'http://kc.allegion.com/',
          text: 'RESOURCES.theKnowledgeCenter'
        },
        {
          link: 'https://us.allegion.com/en/home/document-library/templates.html',
          text: 'RESOURCES.templateDirectory'
        },
        {
          link: 'https://us.allegion.com/content/dam/allegion-us-2/web-documents-2/DataSheet/Allegion_Alliance_Integration_Reference_Guide_111501.pdf', // tslint:disable-line
          text: 'RESOURCES.allegionSoftwareAllianceReferenceGuide'
        },
        {
          link: 'https://us.allegion.com/content/dam/allegion-us-2/web-documents-2/DataSheet/ENGAGE_Technology_Data_Sheet_111427.pdf',
          text: 'RESOURCES.engageBrochure'
        },
        {
          link: 'https://us.allegion.com/content/dam/allegion-us-2/web-documents-2/Brochure/Schlage_Cylinders_and_Key_Blanks_Quick_Reference_Brochure_109158.pdf',
          text: 'RESOURCES.schlageCylinderAndKeywayQuickReferenceGuide'
        },
        {
          link: 'https://us.allegion.com/content/dam/allegion-us-2/web-documents-2/Brochure/Schlage_Wireless_Access_Control_Solutions_Brochure_112159.pdf',
          text: 'RESOURCES.allegionWirelessAccessBrochure'
        }
      )
    }
    return resourceLinks
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
