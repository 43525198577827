import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { cloneDeep, compose, concat, sortBy } from 'lodash/fp'
import { BehaviorSubject, Observable, ReplaySubject, Subject, forkJoin, of } from 'rxjs'
import { map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import {
  CustomerGroup,
  CustomerType,
  Estimate,
  EstimateType,
  Features,
  Quote,
  QuoteExtended,
  QuoteType,
  ServiceCenterDescriptions,
  ShipFromLocation,
  State
} from '../../../models'

import { ControlsOf, FunctionKeys, TableConfig, TableService, filterEventByTableName } from '@oeo/common'
import { EstimatesService } from '../../../services/estimates/estimates.service'
import { HelperService, NameRegex } from '../../../services/helper/helper.service'
import { PurchaseOrderService } from '../../../services/purchase-order/purchase-order.service'
import { QuotesService } from '../../../services/quotes/quotes.service'
import { SearchAndFilterService } from '../../../services/search-and-filter/search-and-filter.service'
import { ShipFromLocationsService } from '../../../services/ship-from-locations/ship-from-locations.service'
import { AddressService } from './../../../services/address/address.service'
import { RoutingService } from './../../../services/routing/routing.service'

interface DialogData {
  action: string
  estimate: Estimate
  estimateType: EstimateType
  quoteNumber: string
  radioDisabled: boolean
  showHollowMetal: boolean
}

type EstimateDialogDetails = {
  name: string
  description?: string
  quote: Quote
  searchQuoteValue: string
  shipToState?: string
  freightCost?: number
  shipFromLocationId?: number
  hollowMetal?: boolean
  customerGroupId?: number
}

@Component({
  selector: 'oa-estimates-dialog',
  templateUrl: './estimates-dialog.component.html',
  styleUrls: ['./estimates-dialog.component.scss']
})
export class EstimatesDialogComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)
  loading = new BehaviorSubject<boolean>(false)
  loading$ = this.loading.asObservable()

  isSaving = false
  form!: FormGroup<ControlsOf<EstimateDialogDetails>>
  shipFromLocations: ShipFromLocation[] = []
  serviceCenterDescriptions = ServiceCenterDescriptions
  flags: Features

  quotes$ = new Subject<QuoteExtended[]>()
  noQuote: QuoteExtended = new QuoteExtended({
    quoteNumber: this.helperService.translate('noQuote'),
    name: this.helperService.translate('buyingProgram'),
    selected: true,
    expirationDate: null
  })
  originalQuotes!: QuoteExtended[]
  searchKeys = ['quoteNumber', 'name']

  customerGroup: CustomerGroup[]

  customerType = CustomerType
  type: 'Estimate' | 'Project' | 'Order'
  root: '/estimates' | '/projects'
  tableConfig: TableConfig<EstimatesDialogComponent> = new TableConfig<EstimatesDialogComponent>()
  showShipFromLocation!: boolean
  isADSystems!: boolean
  isStanley!: boolean
  usedQuotes!: Set<string>
  states: State[]

  get showShipFromLocationWarning(): boolean {
    if (!this.flags.enableElevation) {
      return false
    }
    const shipFromLocationId = this.form.controls.shipFromLocationId.value
    return this.shipFromLocations?.find((x) => x.id === shipFromLocationId)?.locationType == 'Service Center'
  }
  constructor(
    public dialogRef: MatDialogRef<EstimatesDialogComponent>,
    public dialog: MatDialog,
    public estimateService: EstimatesService,
    public quotesService: QuotesService,
    public searchAndFilterService: SearchAndFilterService,
    public helperService: HelperService,
    public routingService: RoutingService,
    public tableActionsService: TableService<EstimatesDialogComponent>,
    public shipFromLocationsService: ShipFromLocationsService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public purchaseOrderService: PurchaseOrderService,
    public addressService: AddressService
  ) {
    this.flags = this.routingService.firstChildData.flags
    this.showShipFromLocation = this.flags.hollowMetalStock && this.dialogData.showHollowMetal
    this.isADSystems = this.dialogData.estimateType === EstimateType.ADSystems
    this.isStanley = this.dialogData.estimateType === EstimateType.Stanley
    if (this.isStanley) {
      this.estimateService
        .getStanleyCustomerGroup()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((groups) => {
          this.customerGroup = groups
        })
    }
    this.addressService
      .getCountries()
      .pipe(
        switchMap((coutries) => {
          const us = coutries.find((country) => country.code === 'USA')
          return this.addressService.getStatesByCountryId(us.id)
        })
      )
      .subscribe({
        next: (states) => {
          this.states = states
          if (this.isADSystems && !states.some((s) => s.code === 'PU')) {
            this.states.unshift({
              id: '999',
              country: 'USA',
              countryId: 'B719EAB1-DEC8-47D7-9511-BF36DECFEBA9',
              name: 'Everett, WA (pick up)',
              code: 'PU',
              isActive: true
            })
          }
        },
        error: (error) => this.helperService.handleError(error)
      })
    this.createForm()
    this.tableConfig = {
      name: this.helperService.translate('availableQuotes'),
      radioDisabled: dialogData.radioDisabled,
      radioButtons: true,
      hoverRows: !dialogData.radioDisabled,
      columns: [
        { header: 'TABLE_HEADERS.quoteNumber', value: 'quoteNumber', type: 'text' },
        { header: 'TABLE_HEADERS.quoteName', value: 'name', type: 'text' },
        { header: 'TABLE_HEADERS.expirationDate', value: 'expirationDate', type: 'date', format: 'MM/dd/yyyy' }
      ]
    }

    if (this.flags.isDirect) {
      this.type = 'Estimate'
      if (this.dialogData.estimateType === EstimateType.ProExpress) this.type = 'Order'
      this.root = '/estimates'
    } else {
      this.type = 'Project'
      this.root = '/projects'
    }

    this.tableActionsService.rowClickEvent$
      .pipe(filterEventByTableName(this.tableConfig.name), takeUntil(this.destroyed$))
      .subscribe((res) => {
        const quote = res.data.quoteNumber === this.noQuote.quoteNumber ? this.noQuote : res.data
        this.form.controls.quote?.patchValue(quote)
      })
  }

  ngOnInit() {
    if (!this.isADSystems) {
      this.loading.next(true)
      this.getQuotes().subscribe()
    }
  }

  getShipFromLocation() {
    this.shipFromLocationsService.get().subscribe({
      next: (data: ShipFromLocation[]) => {
        this.shipFromLocations = data
      },
      error: (error) => {
        this.helperService.handleError(error.message)
        return of([])
      }
    })
  }

  createForm(): void {
    const shipFromLocationId = this.dialogData?.estimate?.default
      ? this.dialogData?.estimate?.default?.shipFromLocationId
      : null
    const name =
      this.dialogData && this.dialogData.estimate
        ? this.dialogData.action === 'copy'
          ? (this.dialogData.estimate.name += ' (copy)')
          : this.dialogData.estimate.name
        : ''

    this.form = this.fb.group<ControlsOf<EstimateDialogDetails>>({
      name: new FormControl(name, {
        validators: [Validators.maxLength(50), Validators.pattern(NameRegex), Validators.required]
      }),
      description: new FormControl(this.dialogData?.estimate?.description, {
        validators: this.isStanley ? [Validators.required, Validators.maxLength(150)] : [Validators.maxLength(150)]
      }),
      quote: new FormControl(this.dialogData?.estimate?.quote, {
        validators: !this.isADSystems && !this.isStanley ? [Validators.required] : []
      }),
      searchQuoteValue: new FormControl(''),
      shipFromLocationId: new FormControl(shipFromLocationId),
      customerGroupId: new FormControl(this.dialogData?.estimate?.customerGroupId)
    })

    if (this.isStanley) {
      this.form.controls.customerGroupId.patchValue(this.dialogData.estimate?.customerGroupId)
    }

    this.form.controls.searchQuoteValue?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => this.filterQuotes(value))
    if (this.isADSystems) {
      this.form.addControl(
        'shipToState',
        new FormControl(this.dialogData.estimate?.shipToStateCode ?? '', [Validators.required])
      )
      this.form.addControl('freightCost', new FormControl(this.dialogData?.estimate?.adsFreightTotalCost ?? 0))
      if (!this.flags.adsEstimator) {
        this.form.controls.freightCost.disable()
      }
    }
    if (this.showShipFromLocation) {
      this.getShipFromLocation()
      this.form.addControl('hollowMetal', new FormControl())

      this.form.controls.shipFromLocationId.disable()
      this.form.controls.shipFromLocationId.patchValue(this.dialogData.estimate?.default?.shipFromLocationId)

      if (this.dialogData.action !== 'create') {
        this.form.controls.shipFromLocationId.enable()
      }
      this.form.controls.hollowMetal?.valueChanges
        .pipe(
          map((hollowMetal) => {
            if (hollowMetal) {
              this.form.controls.shipFromLocationId?.enable()
              this.form.controls.shipFromLocationId.addValidators([Validators.required])
              this.quotes$.next(this.originalQuotes)
            } else {
              this.form.controls.shipFromLocationId.removeValidators([Validators.required])
              if (this.flags.hollowMetalStock) {
                this.form.controls.shipFromLocationId?.reset()
                this.form.controls.shipFromLocationId?.disable()
                this.quotes$.next(this.originalQuotes)
              }
            }
          })
        )
        .pipe(takeUntil(this.destroyed$))
        .subscribe()
    }
  }

  getTitle(
    action: 'create' | 'edit' | 'copy',
    estimateType: EstimateType,
    type: 'Project' | 'Estimate' | 'Order'
  ): string {
    if (!action || !type) {
      return
    }
    let title = 'TITLE.' + action.toLocaleLowerCase()
    switch (estimateType) {
      case EstimateType.ProExpress:
        title += 'PE'
        break
      case EstimateType.ADSystems:
        title += 'AdSystems'
        break
      case EstimateType.Stanley:
        title += 'Stanley'
        break
      default:
        break
    }
    title += type
    return title
  }

  getQuotes(): Observable<QuoteExtended[]> {
    return this.quotesService.get().pipe(
      mergeMap((quotes: Quote[]) => {
        return forkJoin({
          usedQuotes:
            this.dialogData.estimateType === EstimateType.ADSystems
              ? this.purchaseOrderService.quotesAppliedToPurchaseOrder(quotes.map((quote) => quote.quoteNumber))
              : of([]),
          quotes: of(quotes)
        })
      }),
      tap(({ usedQuotes }) => (this.usedQuotes = new Set(usedQuotes))),
      map(({ quotes }) =>
        compose(concat([this.noQuote]), this.handleQuotesForCanadaUseCase, this.handleQuotesForADSystemsUseCase)(quotes)
      ),
      tap((quotes) => {
        this.originalQuotes = cloneDeep(quotes)
        this.quotes$.next(this.filterAndSetSelectedQuote(quotes))
        this.loading.next(false)
      }),
      take(1)
    )
  }

  /** For Canada Users, show strategic growth quotes on top and the first preselected */
  handleQuotesForCanadaUseCase = (quotes: QuoteExtended[]): QuoteExtended[] => {
    if (this.flags?.isCanada && !this.copyingADSEstimate()) {
      quotes = sortBy<QuoteExtended>((quote) => (quote.type === QuoteType.StrategicGrowth ? 0 : 1))(quotes)
      // If a Strategic quote exists, preselect it for Canada Users
      if (quotes.length && quotes.first().type === QuoteType.StrategicGrowth) {
        this.noQuote.selected = false
        quotes.first().selected = true
      }
    }
    return quotes
  }

  handleQuotesForADSystemsUseCase = (quotes: Quote[]): QuoteExtended[] => {
    return quotes
      .filter((quote) => {
        if (this.dialogData.estimateType === EstimateType.ADSystems)
          return quote.type === QuoteType.ADSystems && !this.usedQuotes.has(quote.quoteNumber)
        else return quote.type !== QuoteType.ADSystems
      })
      .map((quote) => new QuoteExtended({ ...quote, selected: false }))
  }

  /**
   * Preselect associated quote when update estimate
   * Preselect quote when request for re-quote and no estimate associated */
  filterAndSetSelectedQuote(_quotes: QuoteExtended[]): QuoteExtended[] {
    let quotes = cloneDeep(_quotes)

    let selectedQuoteNumber: string
    if (this.dialogData) {
      if (this.dialogData.estimate) {
        selectedQuoteNumber =
          this.dialogData.estimate.quote && !this.copyingADSEstimate()
            ? this.dialogData.estimate.quote.quoteNumber
            : this.selectDefaultQuoteNumber(quotes)
      } else {
        selectedQuoteNumber = this.dialogData.quoteNumber || this.selectDefaultQuoteNumber(quotes)
      }
    } else {
      selectedQuoteNumber = this.selectDefaultQuoteNumber(quotes)
    }
    const selectedQuote = quotes.find((quote) => quote.quoteNumber === selectedQuoteNumber)
    if (selectedQuote) {
      quotes = quotes.map((quote) => ({ ...quote, selected: quote.quoteNumber === selectedQuote.quoteNumber }))
      this.form.controls.quote.patchValue(selectedQuote)
    } else {
      this.form.controls.quote.patchValue(null)
      quotes.forEach((quote) => (quote.selected = false))
    }
    return quotes
  }

  copyingADSEstimate(): boolean {
    return this.dialogData.action === 'copy' && this.dialogData.estimate?.estimateTypeId === EstimateType.ADSystems
  }

  selectDefaultQuoteNumber(quotes: QuoteExtended[]): string {
    if (this.flags.isCanada) {
      return quotes.length && quotes.first()?.type === QuoteType.StrategicGrowth
        ? quotes.first().quoteNumber
        : this.noQuote.quoteNumber
    } else {
      return this.noQuote.quoteNumber
    }
  }

  trim(value: string, formControlName: string): void {
    this.form.get(formControlName)?.patchValue(value ? value.trim() : value)
  }

  onSubmit(): void {
    const estimate = this.dialogData.estimate ? cloneDeep(this.dialogData.estimate) : new Estimate()
    estimate.name = this.form.controls.name?.value
    estimate.quote =
      !this.form.controls.quote?.value || this.form.controls.quote?.value.quoteNumber === this.noQuote.quoteNumber
        ? null
        : this.form.controls.quote?.value
    estimate.estimateTypeId = this.dialogData.estimateType
    if (this.isStanley) {
      estimate.description = this.form.controls.description?.value
      estimate.customerGroupId = this.form.controls.customerGroupId?.value
    }
    if (this.isADSystems) {
      estimate.shipToStateCode = this.form.controls.shipToState.value
      estimate.adsFreightTotalCost = this.form.controls.freightCost.value
    }
    const shipFromLocationId = this.form.controls.shipFromLocationId?.value
    this[this.dialogData.action.toLocaleLowerCase() as FunctionKeys<EstimatesDialogComponent>].apply(this, [
      estimate,
      shipFromLocationId
    ])
  }

  create(payload: Estimate, shipFromLocationId: number = null): void {
    this.isSaving = true
    this.estimateService
      .createEstimate(payload)
      .pipe(
        mergeMap((estimate: Estimate) => {
          if (!estimate || !shipFromLocationId) {
            return of(estimate)
          }
          const shipFromLocationDefault = { estimateId: estimate.id, shipFromLocationId: shipFromLocationId }
          return this.shipFromLocationsService.createEstimateShipFromLocation(shipFromLocationDefault)
        }),
        take(1)
      )
      .subscribe({
        next: (estimate) => {
          if (estimate.estimateTypeId === EstimateType.ProExpress) {
            this.routingService.navigate(['/pro-express', estimate.id])
          } else if (estimate && estimate.id) {
            this.routingService.navigate(['/estimates', estimate.id])
          } else {
            this.routingService.navigate(['/estiamtes'])
          }
          this.dialogRef.close()
        },
        error: (error) => {
          this.isSaving = false
          this.helperService.handleError(error.message)
        }
      })
  }

  edit(payload: Estimate, shipFromLocationId: number = null): void {
    this.isSaving = true
    const defaultId = payload.default ? payload.default.id : null
    const shipFromLocationPayload = { estimateId: payload.id, shipFromLocationId }
    const updateShipFromLocation$ = this.shipFromLocationsService
      .updateEstimateShipFromLocation(defaultId, shipFromLocationPayload)
      .pipe(take(1))
    const updateEstimate$ = this.estimateService.updateEstimate(payload).pipe(take(1))
    if (shipFromLocationId) {
      updateShipFromLocation$.pipe(mergeMap(() => updateEstimate$)).subscribe({
        next: () => this.dialogRef.close(),
        error: (error) => {
          this.helperService.handleErrorObject(error)
        }
      })
    } else
      updateEstimate$.subscribe({
        next: () => this.dialogRef.close(),
        error: (err) => {
          this.helperService.handleErrorObject(err)
        }
      })
  }

  copy(payload: Estimate): void {
    this.isSaving = true
    if (this.copyingADSEstimate) {
      payload.quote = null
      payload.quoteNumber = null
    }

    this.estimateService
      .copyEstimate(payload)
      .pipe(take(1))
      .subscribe({
        next: (id) => {
          this.routingService.navigate([this.root, id])
          this.dialogRef.close()
        },
        error: (error) => this.helperService.handleError(error.message),
        complete: () => this.dialogRef.close()
      })
  }

  filterQuotes(value: string): void {
    if (!value) {
      this.quotes$.next(this.originalQuotes)
      return
    }
    this.quotes$.next(this.searchAndFilterService.searchAndFilter(null, this.originalQuotes, this.searchKeys, value))
  }

  isDisabled(): boolean {
    return (
      this.form.invalid ||
      this.isSaving ||
      this.loading.value ||
      (this.form.controls.hollowMetal?.value && !this.form.controls.shipFromLocationId?.value)
    )
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
