<div class="lib-prep-container">
  <div class="left">
    <mat-form-field class="no-hint" *ngIf="!!referencePoints">
      <mat-label>{{ 'FORMFIELDS.referencePoint' | translate }}</mat-label>
      <mat-select [(ngModel)]="referencePoint" required>
        <mat-option *ngFor="let c of referencePoints" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="no-hint">
      <mat-label>{{ 'FORMFIELDS.degreeOfOpening' | translate }}</mat-label>
      <input matInput name="degreeOfOpening" [(ngModel)]="prep.degreeOfOpening" [type]="'number'" />
    </mat-form-field>
    <lib-special-hardware [component]="this" [isSpecialPrep]="isSpecialPrep"></lib-special-hardware>
  </div>
  <lib-door-prep *ngIf="door" [door]="door"></lib-door-prep>
</div>
