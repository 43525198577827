import { Component, Input, Renderer2, ElementRef } from '@angular/core'
import { uniq, without } from 'lodash'
import { TableCellClass, TableColumn } from '../../../../types'

export type TDefaultComponentData = {
  hasNotification?: () => boolean
}
@Component({
  selector: 'oa-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent<T> {
  @Input() row!: TDefaultComponentData
  @Input() column!: TableColumn<T>
  @Input() data!: any

  warningMessages: string[] = []
  showWarning: boolean = false
  get classes() {
    return this.getClasses(this.row, this.column?.validators?.tableCellClassFns ?? [])
  }

  constructor(protected renderer: Renderer2, protected hostElement: ElementRef) {}

  showNotification() {
    if (!this.row?.hasNotification) return false
    return this.column?.notification && this.row?.hasNotification()
  }

  get dataIsEmptyObj(): boolean {
    if (this.data && typeof this.data === 'object' && Object.keys(this.data).length === 0) {
      return true
    }
    return false
  }

  /**
   * Decides whether a list of classes should be applied to the host component based on an input function
   *
   * @param row - the data passed onto the row
   * @param tableCellClasses - list of objects containing a function and a class
   * @returns classes to be attached to the host component
   */
  getClasses(row: any, tableCellClasses: TableCellClass[]): string {
    let classes = ''
    if (this.column?.chipClass) {
      classes = 'chip ' + this.column.chipClass(this.row)
    }
    if (this.column?.truncateCol) {
      classes += 'truncate'
    }

    if (!tableCellClasses) return classes

    classes += tableCellClasses
      .map(({ shouldSetClass, classToSet, warningMessage, showWarning }) => {
        if (showWarning && warningMessage && shouldSetClass(row)) {
          this.showWarning = true
          this.warningMessages = uniq([warningMessage, ...this.warningMessages])
          this.renderer.addClass(this.hostElement.nativeElement, 'contains-hint')
        } else {
          if (warningMessage) {
            this.warningMessages = [...without(this.warningMessages, warningMessage)]
          }
          if (this.warningMessages.length === 0) {
            this.showWarning = false
            this.renderer.removeClass(this.hostElement.nativeElement, 'contains-hint')
          }
        }
        return shouldSetClass(row) ? classToSet : ''
      })
      .join(' ')
    if (this.column.tableName == 'products' && classes.includes('warning')) {
      this.hostElement.nativeElement.querySelector('input')?.classlist.removeClass('warning')
    }

    return classes
  }

  getToolTipValue() {
    let tooltipValue = ''
    if (this.column?.tooltip) {
      if (typeof this.column.tooltip === 'string') tooltipValue = this.column.tooltip
      else if (typeof this.column.tooltip === 'function') tooltipValue = this.column?.tooltip(this.row)
    }
    return tooltipValue
  }
}
