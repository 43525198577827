import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component'
import { SideNavComponent } from './components/side-nav/side-nav.component'
import { HeaderComponent } from './components/header/header.component'
import { CreateNewDialogComponent } from './components/side-nav/create-new-dialog/create-new-dialog.component'
import { LandingComponent } from './components/landing/landing.component'
import { IndirectNewProjectDialogComponent } from './components/side-nav/create-new-dialog/indirect-new-project-dialog/indirect-new-project-dialog.component'
import { TermsAndConditionsComponent } from './components/profile-information/terms-and-conditions/terms-and-conditions.component'
import { ProfileInfoComponent } from './components/profile-information/profile-info.component'
import { CustomerInformationComponent } from './components/profile-information/customer-information/customer-information.component'
import { LocksmithIDNumbersComponent } from './components/profile-information/locksmith-id-numbers/locksmith-id-numbers.component'
import { InternalLandingComponent } from './components/internal-landing/internal-landing.component'
import { SharedModule } from '../shared/shared.module'
import { NotificationsComponent } from './components/notifications/notifications.component'
import { NotificationComponent } from './components/notification/notification.component'
import { ThirdPartyLicensesComponent } from './components/third-party-licenses/third-party-licenses.component'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field'
import { UserOptionsDialogComponent } from './components/user-options-dialog/user-options-dialog.component'
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu'

@NgModule({
  imports: [CommonModule, SharedModule, TourMatMenuModule],
  declarations: [
    BreadcrumbsComponent,
    SideNavComponent,
    HeaderComponent,
    CreateNewDialogComponent,
    IndirectNewProjectDialogComponent,
    LandingComponent,
    ProfileInfoComponent,
    CustomerInformationComponent,
    LocksmithIDNumbersComponent,
    TermsAndConditionsComponent,
    InternalLandingComponent,
    NotificationsComponent,
    NotificationComponent,
    ThirdPartyLicensesComponent,
    UserOptionsDialogComponent
  ],
  exports: [BreadcrumbsComponent, SideNavComponent, HeaderComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', floatLabel: 'always' } as MatFormFieldDefaultOptions
    }
  ]
})
export class CoreModule {}
