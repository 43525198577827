import { InjectionToken } from '@angular/core'
import { Subject } from 'rxjs'

export const SEARCH_AND_FILTER_SERVICE = new InjectionToken('SearchAndFilterService')
export interface ISearchAndFilterService {
  buttonClickTrackEvent: Subject<string>
  searchAndFilter<Item>(
    filters: { [key: string]: string | boolean },
    data: Item[],
    searchKeys: string[],
    searchValue: string,
    searchParams?: Record<string, string>
  ): Item[]
}
