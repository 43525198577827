<div class="lib-prep-container">
  <form class="left" #PrepFieldsForm="ngForm">
    <mat-form-field class="no-hint">
      <mat-label>{{ title }}</mat-label>
      <input
        matInput
        [ngModel]="getDisplayValue(code)"
        (ngModelChange)="setPrepCode($event)"
        [matAutocomplete]="auto"
        #prepInput
        required
        [libValidValueValidator]="codes"
        validatorTransformFn="preps"
        name="prepCode"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of codes | libAutoCompleteFilter:prepInput.autocompleteValue:'preps'"
          [value]="getDisplayValue(option)"
        >
          {{ getDisplayValue(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="no-hint">
      <mat-label>{{'FORMFIELDS.location' | translate}}</mat-label>
      <input
        #locationInput
        matInput
        [matAutocomplete]="locationAutocomplete"
        name="location"
        [(ngModel)]="prep.locationType"
        [disabled]="!prep.code"
        required
        [libValidValueValidator]="locations"
        validatorTransformFn="value"
      />
      <mat-autocomplete autoActiveFirstOption #locationAutocomplete="matAutocomplete">
        <mat-option *ngFor="let option of locations | libAutoCompleteFilter:locationInput.autocompleteValue:'value'" [value]="option.value">
          {{ option.value }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="no-hint">
      <mat-label>{{ ('FORMFIELDS.referencePoint' | translate) }}</mat-label>
      <mat-select
        [(ngModel)]="prep.referencePoint"
        [disabled]="!code || code.fixedLocation || prep.locationType !== specialPrepLocation.value"
        required
        name="referencePoint"
      >
        <mat-option *ngFor="let c of referencePoints" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <lib-door-prep *ngIf="door" [door]="door" [prepLocationInputsFilled]="prepLocationInputsFilled"></lib-door-prep>
</div>
