import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, from,  mergeMap, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { ToolType } from '../core/enums/tool-type';
import { nameOf } from '@oeo/common';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../core/interfaces/i-environment';
import { Tool } from '../core/models/tool';
import { DialogService } from '../core/services/dialog.service';
import { FrameElevationService } from '../core/services/frame-elevation.service';
import { PreferenceService } from '../core/services/preference.service';
import { DrawingPadComponent } from './components/drawing-pad/drawing-pad.component';
import { ConfigurationComponent } from './dialogs/configuration/configuration.component';
import { FrameElevationSettingsComponent } from './dialogs/frame-elevation-settings/frame-elevation-settings.component';
import { PrepsDialogComponent } from './dialogs/preps/preps-dialog.component';
import { FrameElevationExport } from './models/exports/frame-elevation-export';
import { FrameElevation } from './models/frame-elevation';
import { Stick } from './models/stick';
import { DoorService } from './services/door.service';
import { GlassService } from './services/glass.service';
import { IntersectableService } from './services/intersectable.service';
import { StickService } from './services/stick.service';
import { FrameElevationConfigService } from './services/frame-elevation-config.service';
import { IElevationComponent } from '../core/interfaces/i-elevation-component';
@Component({
  selector: 'lib-frame-elevation',
  templateUrl: './frame-elevation.component.html',
  styleUrls: ['./frame-elevation.component.scss'],
  providers: [GlassService, StickService, DoorService, IntersectableService],
})
export class FrameElevationComponent implements OnInit,OnDestroy, IElevationComponent {
  private destroyed$ = new Subject<void>();
  @ViewChild('inputRef', { static: true }) inputRef: ElementRef<HTMLInputElement>;
  @ViewChild('drawingPad', { static: false }) drawingPad: DrawingPadComponent;

  @Output() save$ = new EventEmitter<string>();
  isCustomizing = false;

  get input() {
    return this.inputRef.nativeElement;
  }

  get elevation(): FrameElevation {
    return this.service.elevation;
  }

  get configuration(): string {
    return this.service.elevation.toXML();
  }

  tools: Tool[];
  tool: Tool;
  help: boolean;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    public service: FrameElevationService,
    public configService: FrameElevationConfigService,
    private preferenceService: PreferenceService,
    @Inject(ENVIRONMENT_INJECTION_TOKEN) public environment: IEnvironment,
  ) { }

  ngOnInit(): void {
    this.tools = [
      { type: ToolType.Pan, disabled: () => false, active: false, visible: () => true },
      {
        type: ToolType.Draw,
        disabled: () => !this.isCustomizing || !this.service.elevation?.editable,
        active: false,
        visible: () => true,
      },
      {
        type: ToolType.Glass,
        disabled: () => !this.isCustomizing || !this.service.elevation?.editable,
        active: false,
        visible: () => true,
      },
      {
        type: ToolType.Door,
        disabled: () => !this.isCustomizing || !this.service.elevation?.editable,
        active: false,
        visible: () => true,
      },
      {
        type: ToolType.Flip,
        disabled: () => !this.isCustomizing || !this.service.elevation?.editable,
        active: false,
        visible: () => true,
      },
      {
        type: ToolType.Undo,
        disabled: () => !this.isCustomizing || !this.service.elevation?.editable,
        active: false,
        visible: () => true,
      },
      {
        type: ToolType.Redo,
        disabled: () => !this.isCustomizing || !this.service.elevation?.editable,
        active: false,
        visible: () => true,
      },
      {
        type: ToolType.Preps,
        disabled: () =>
          !this.isCustomizing && this.service.elevation?.intersectables?.any(i => i instanceof Stick && i.isHead),
        active: false,
        visible: () => true,
      },
      { type: ToolType.Grid, disabled: () => !this.isCustomizing, active: false, visible: () => true },
      { type: ToolType.Ruler, disabled: () => !this.isCustomizing, active: false, visible: () => true },
    ];
    this.tool = this.tools[0];
    this.initializeFrameElevation()
  }

  initializeFrameElevation(){
    this.service.elevation = null
    const id = this.route.snapshot.queryParams.id;
    const routeState = window.history.state as { configuration?: string }
    if (routeState.configuration) {
      this.service.elevation = new FrameElevation(FrameElevation.fromXML(routeState.configuration));
      setTimeout(() => {
        this.isCustomizing = true;
        this.service.elevation.customize();
      })
    }
    else if (id) {
      this.service.getById(id, []).subscribe(elevation => {
        this.service.elevation = new FrameElevation(FrameElevation.fromXML(elevation.configuration), elevation);
        this.isCustomizing = true;
        this.service.elevation.customize();
      });
    }
    else {
      this.preferenceService
        .getByPreferenceTypeId(2, +this.route.parent.snapshot.params.estimateId).pipe(take(1))
        .subscribe(preference => this.openSettings(FrameElevation.fromXML(preference?.configuration), true));
    }
  }

  save() {
    this.save$.next(this.service.elevation.toXML());
  }

  money() {
    this.dialogService.open(ConfigurationComponent, this.service.elevation, { closeable: true });
  }

  openSettings(frameElevation?: FrameElevationExport, isNew: boolean = false) {
    const properties: Record<string,boolean> = {};
    properties[nameOf((_: FrameElevation) => _.unitOfMeasure)] = this.isCustomizing;
    properties[nameOf((_: FrameElevation) => _.height)] = this.isCustomizing;
    properties[nameOf((_: FrameElevation) => _.width)] = this.isCustomizing;
    properties[nameOf((_: FrameElevation) => _.series)] = this.isCustomizing;
    properties[nameOf((_: FrameElevation) => _.templateType)] = this.isCustomizing;
    this.dialogService
      .open<
        { properties: Record<string,boolean>; elevation: FrameElevation; isNew: boolean; step: number },
        FrameElevationExport
      >(
        FrameElevationSettingsComponent,
        { elevation: this.service.elevation ? this.service.elevation : new FrameElevation(frameElevation), properties, isNew, step: 0 },
        {
          closeable: false,
        }
      )
      .pipe(
        mergeMap(result => from(this.setUpFrameElevation(result, false))),
        mergeMap((result): Observable<null | FrameElevationExport> => {
          if (!isNew && result) return of(null);
          return this.dialogService.open(PrepsDialogComponent, { frame: this.service.elevation }, { closeable: true });
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((result) =>
        result ? this.setUpFrameElevation(result, true) : this.setUpFrameElevation(this.service.elevation?.toElevationExport(), true)
      );
  }



  setUpFrameElevation(elevation: FrameElevationExport, customize: boolean): Promise<FrameElevationExport | typeof EMPTY>{
    if (!(!!elevation || !!this.service.elevation)) {
      return this.router.navigate(['../home'], { relativeTo: this.route }).then(() => null);
    }

    if (this.service.elevation) {
      this.service.elevation.update(elevation);
    } else {
      this.service.elevation = new FrameElevation(elevation);
    }

    if(!customize) return Promise.resolve(elevation)
    if (this.service.elevation.templateType == null) {
      this.isCustomizing = true;
    }
    if (this.isCustomizing) {
      this.service.elevation.customize();
    }
    return new Promise((resolve) =>{
      setTimeout(() => {
        this.customize()
        resolve(elevation)
      });
    })
  }

  customize() {
    this.service.elevation.customize();
    this.service.elevation.source = FrameElevation.fromXML(this.service.elevation.toXML());
    this.isCustomizing = true;
  }

  setTool(tool: Tool): void {
    if (tool.type === ToolType.Preps) {
      return this.openPreps();
    }
    this.tool = tool;
  }

  openPreps(): void {
    this.dialogService.open(PrepsDialogComponent, {frame: this.service.elevation}, { closeable: true }).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
