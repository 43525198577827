import { InjectionToken } from '@angular/core'
import { Observable } from 'rxjs'

interface IFeatures {
  internal: boolean
  isCanada: boolean
  isDirect: boolean
  adSystems: boolean
}
export abstract class BaseFlagsService {
  abstract flags$: Observable<IFeatures>
  abstract getFlags(): void
}
export const FLAGS_SERVICE = new InjectionToken<BaseFlagsService>('FLAGS_SERVICE')
